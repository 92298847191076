import { Box, Image, Theme, Typography } from "@outschool/backpack";
import { useSession } from "@outschool/ui-auth";
import React from "react";

import OutschoolLogo from "../../../../images/Outschool_Author_Logo.webp";
import { Sender } from "../consts";

const DEFAULT_PROFILE_IMAGE =
  "https://cdn.filestackcontent.com/26Xc9cgQFmpVTv8h268V";

type MessageProps = {
  text: string;
  sender: Sender;
  children?: React.ReactElement;
};

export const Message = ({ text, sender, children }: MessageProps) => {
  const { currentUser } = useSession();
  const isSystemMessage = sender === Sender.System;

  return (
    <Box
      flex
      sx={{
        flexDirection: isSystemMessage ? "row" : "row-reverse",
        gap: "0.5rem"
      }}
    >
      <Image
        sx={{
          width: "44px",
          height: "44px",
          borderRadius: "100px"
        }}
        src={
          isSystemMessage
            ? OutschoolLogo
            : currentUser?.photo || DEFAULT_PROFILE_IMAGE
        }
      />
      <Box
        flex
        sx={(theme: Theme) => ({
          flexDirection: "column",
          width: "100%",
          borderRadius: "1rem",
          borderTopLeftRadius: isSystemMessage ? "0.25rem" : "1rem",
          borderTopRightRadius: isSystemMessage ? "1rem" : "0.25rem",
          padding: "2rem",
          backgroundColor: isSystemMessage
            ? theme.colors.peacock50
            : theme.colors.gray50,
          gap: "1.6rem",
          overflow: "auto"
        })}
      >
        {text && <Typography>{text}</Typography>}
        {children}
      </Box>
    </Box>
  );
};
