import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Icon,
  Menu,
  PopoverProps,
  SxProps,
  Theme,
  Typography,
  useTheme
} from "@outschool/backpack";
import {
  faArrowRight,
  faArrowUpRightFromSquare,
  faExclamationTriangle,
  faInfoCircle
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { joinMembershipPath, transactionsPath } from "@outschool/routes";
import { dayjs, formatDate, startOfIsoWeekUtc } from "@outschool/time";
import { useTrackEvent } from "@outschool/ui-analytics";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { CreditIcon, TrackedButton } from "@outschool/ui-components-shared";
import { CoinIcon } from "@outschool/ui-components-website";
import { Screen, useLinkComponent, useNavigation } from "@outschool/ui-utils";
import { sortBy } from "lodash";
import React from "react";
import { useLocation } from "react-router";

import * as Env from "../../../shared/Env";
import {
  UpcomingChargesQueryQuery,
  UpcomingChargesQueryQueryVariables
} from "../../../shared/generated/graphql";
import { useSubscriptionCreditBalance } from "../../hooks/useSubscriptionCreditBalance";
import { useTimeZone } from "../../providers/TimeZoneProvider";
import { useStripeCheckout } from "../../routes/Subscriptions/hooks/useStripeCheckout";
import { StripeSubscriptionStatus } from "../transactions/SubscriptionMembershipCard";
import { SubscriptionEsaDisableTooltip } from "./SubscriptionEsaDisableTooltip";

export function SubscriptionCreditNavItem() {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionCreditNavItem"
  );
  const { isEsaSession } = useSession();
  const theme = useTheme();
  const {
    status,
    availableCredits,
    rolloverSubscriptionCreditLimit,
    nextSubscriptionStartDate,
    loading: loadingSubscriptionCredits,
    cancelAtPeriodEnd
  } = useSubscriptionCreditBalance();
  const { timeZone } = useTimeZone();
  const [showModal, setShowModal] = React.useState<null | HTMLElement>(null);
  const [isCreditsModalOpen, setIsCreditsModalOpen] = React.useState(false);
  const [AddCreditsModal, setAddCreditsModal] =
    React.useState<React.ComponentType<any> | null>(null);
  const navigate = useNavigation();
  const { start } = React.useMemo(() => {
    const now = dayjs();
    return {
      start: now.toDate()
    };
  }, []);

  const { upComingChargesByWeek } = useUpcomingChargesByWeek(
    start,
    nextSubscriptionStartDate
  );

  const upComingChargesWithinBillingPeriod = upComingChargesByWeek.filter(
    charge => dayjs(charge.date).isBefore(dayjs(nextSubscriptionStartDate))
  );

  const numCreditsNeeded = upComingChargesWithinBillingPeriod.reduce(
    (acc, charge) => acc + charge.priceCredits,
    0
  );

  const isSmallScreen = Screen.useIsSmall();

  if (loadingSubscriptionCredits || status === "canceled") {
    return null;
  }

  const navItemTextColor = isEsaSession
    ? "grey.500"
    : theme.palette.common.black;

  const handleOpenCreditsModal = async () => {
    // Dynamically import the modal when the button is clicked
    const module = await import(
      /* webpackChunkName: "subscriptions" */ "../subscriptions/AddCreditsModal"
    );
    setAddCreditsModal(() => module.default);
    setIsCreditsModalOpen(true);
    setShowModal(null);
  };

  const modalPosition: {
    anchorOrigin: PopoverProps["anchorOrigin"];
    transformOrigin: PopoverProps["transformOrigin"];
  } = isSmallScreen
    ? {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        transformOrigin: { vertical: "top", horizontal: "center" }
      }
    : {
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        transformOrigin: { vertical: "top", horizontal: 100 }
      };

  return (
    <>
      <SubscriptionEsaDisableTooltip
        component="SubscriptionCreditNavItem"
        tooltip={t(
          "Membership credits aren’t available in a ClassWallet session. To use your credits"
        )}
        placement="top"
      >
        <TrackedButton
          variant="link"
          trackingName="subscription_credit_nav_item"
          data-test-id="subscription-credit-nav-item"
          sx={(theme: Theme) => ({
            border: "none",
            paddingX: 8,
            paddingTop: 6,
            display: "block",
            color: navItemTextColor,
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              margin: "auto",
              paddingTop: "18px"
            }
          })}
          disabled={isEsaSession}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setShowModal(e.currentTarget);
          }}
        >
          <Box flex={true}>
            <Box sx={{ paddingTop: "0.2rem", paddingRight: "0.5rem" }}>
              <CreditIcon
                fillColor={
                  isEsaSession
                    ? theme.palette.grey[300]
                    : availableCredits >= numCreditsNeeded
                    ? theme.palette.warning.main
                    : theme.palette.grey[200]
                }
                strokeColor={
                  isEsaSession
                    ? theme.palette.grey[700]
                    : availableCredits >= numCreditsNeeded
                    ? theme.palette.warning[700]
                    : theme.palette.grey[500]
                }
                sx={{ width: "2.4rem", height: "2.4rem" }}
              />
            </Box>
            <Typography
              variant="h5"
              sx={{
                color: navItemTextColor,
                fontWeight: "600"
              }}
            >
              {`${availableCredits}`}
            </Typography>
          </Box>
          <Box
            sx={(theme: Theme) => ({
              display: "flex",
              flexDirection: "row-reverse",
              [theme.breakpoints.down("sm")]: {
                paddingLeft: "0.5rem"
              }
            })}
          >
            <Typography
              variant="body2"
              sx={{
                color: navItemTextColor
              }}
            >
              {t("Credits")}
            </Typography>
          </Box>
        </TrackedButton>
      </SubscriptionEsaDisableTooltip>
      <Menu
        anchorEl={showModal}
        open={Boolean(showModal)}
        onClose={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          setShowModal(null);
        }}
        anchorOrigin={modalPosition.anchorOrigin}
        transformOrigin={modalPosition.transformOrigin}
        slotProps={{
          paper: {
            sx: (theme: Theme) => ({
              borderRadius: 16,
              paddingX: 8,
              maxWidth: "420px",
              boxShadow: "0px 0px 4em rgba(0, 0, 0, 0.2)",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "calc(100% - 32px);"
              }
            })
          }
        }}
      >
        <Box
          sx={{
            background:
              "linear-gradient(101deg, #4B01D4 -29.61%, #380596 58.3%)",
            boxShadow:
              "0px 0px 4px 0px rgba(92, 76, 236, 0.66), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "16px",
            padding: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={() => {
            navigate(transactionsPath());
            setShowModal(null);
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {cancelAtPeriodEnd ? (
              <Typography variant="caption" sx={{ color: "white" }}>
                {t`Membership ending`}
              </Typography>
            ) : (
              <Typography variant="caption" sx={{ color: "white" }}>
                {t`Membership renewal`}{" "}
                {`- ${rolloverSubscriptionCreditLimit} credits`}
              </Typography>
            )}
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", display: "block", color: "white" }}
            >
              {nextSubscriptionStartDate &&
                `${dayjs(nextSubscriptionStartDate)
                  .tz(timeZone)
                  .format("MMM D, YYYY")}`}
            </Typography>
            <Box sx={{ marginTop: 16 }}>
              <ManageSubscription />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end"
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 8,
                padding: "0.8rem 1.6rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20
              }}
            >
              <CoinIcon
                fillColor={
                  availableCredits < numCreditsNeeded
                    ? theme.palette.grey[200]
                    : theme.palette.warning.main
                }
                strokeColor={
                  availableCredits < numCreditsNeeded
                    ? theme.palette.grey[500]
                    : theme.palette.warning[700]
                }
              />{" "}
              <Typography variant="h1" sx={{ marginLeft: 4 }}>
                {availableCredits}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              sx={{ color: "white", marginTop: 4 }}
            >{t`Available credits`}</Typography>
          </Box>
        </Box>
        <UpcomingCharges
          subscriptionStatus={status}
          nextSubscriptionStartDate={nextSubscriptionStartDate}
          upComingChargesByWeek={upComingChargesWithinBillingPeriod}
          numCreditsNeedForPeriod={numCreditsNeeded}
          availableCredits={availableCredits}
          rolloverSubscriptionCreditLimit={rolloverSubscriptionCreditLimit}
          cancelAtPeriodEnd={cancelAtPeriodEnd}
          containerSx={{
            display: "flex",
            justifyContent: "left",
            paddingX: 16,
            width: "100%"
          }}
          onAddMoreCredits={handleOpenCreditsModal}
        />
      </Menu>
      {AddCreditsModal && (
        <AddCreditsModal
          isOpen={isCreditsModalOpen}
          onClose={() => {
            setIsCreditsModalOpen(false);
            setAddCreditsModal(null);
          }}
        />
      )}
    </>
  );
}

function ManageSubscription() {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionCreditNavItem"
  );
  const Link = useLinkComponent();
  const trackEvent = useTrackEvent();

  return (
    <Link
      to={transactionsPath()}
      onClick={() => {
        trackEvent("subscription_credit_nav_item_manage_plan_touch");
      }}
    >
      <Typography
        sx={{ fontWeight: "fontWeightMedium", color: "white", fontSize: 14 }}
      >
        {t`Manage Plan`}
      </Typography>
      &nbsp;
      <Icon
        icon={faArrowRight}
        sx={{ marginRight: 4, color: "white", fontSize: 14 }}
      />
    </Link>
  );
}

const UPCOMING_CHARGES_QUERY = gql`
  query UpcomingChargesQuery($start: DateTime!, $end: DateTime!) {
    currentUser {
      uid
      activeEnrollments(start: $start, end: $end) {
        uid
        upcomingCharges {
          date
          priceCents
          priceCredits
        }
      }
    }
  }
`;

export const useUpcomingChargesByWeek = (
  start: Date | null,
  end: Date | null
) => {
  const { data, loading, error } = useQueryWithPreviousData<
    UpcomingChargesQueryQuery,
    UpcomingChargesQueryQueryVariables
  >(UPCOMING_CHARGES_QUERY, {
    variables: { start, end },
    skip: !start || !end
  });

  const upComingChargesByWeek = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const aggregatedData: {
      [date: string]: {
        priceCents: number;
        priceCredits: number;
        numEnrollments: number;
      };
    } = {};

    data.currentUser?.activeEnrollments?.forEach(enrollment => {
      enrollment?.upcomingCharges.forEach(charge => {
        const isoWeekStart = startOfIsoWeekUtc(
          new Date(charge.date)
        ).toISOString();

        if (!aggregatedData[isoWeekStart]) {
          aggregatedData[isoWeekStart] = {
            priceCents: 0,
            priceCredits: 0,
            numEnrollments: 0
          };
        }

        aggregatedData[isoWeekStart].priceCents += charge.priceCents;
        aggregatedData[isoWeekStart].priceCredits += charge.priceCredits;
        aggregatedData[isoWeekStart].numEnrollments += 1;
      });
    });

    return sortBy(
      Object.entries(aggregatedData).map(([date, values]) => ({
        date,
        ...values
      })),
      "date"
    );
  }, [data]);

  return {
    upComingChargesByWeek,
    loading,
    error
  };
};

function UpcomingCharges({
  nextSubscriptionStartDate,
  upComingChargesByWeek,
  numCreditsNeedForPeriod,
  cancelAtPeriodEnd,
  availableCredits,
  rolloverSubscriptionCreditLimit,
  containerSx,
  onAddMoreCredits,
  subscriptionStatus
}: {
  subscriptionStatus: string | null | undefined;
  nextSubscriptionStartDate: Date | null;
  upComingChargesByWeek: {
    date: string;
    priceCents: number;
    priceCredits: number;
    numEnrollments: number;
  }[];
  numCreditsNeedForPeriod: number;
  cancelAtPeriodEnd: boolean | null | undefined;
  availableCredits: number;
  rolloverSubscriptionCreditLimit: number;
  containerSx?: SxProps;
  onAddMoreCredits: () => void;
}) {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionCreditNavItem"
  );
  const navigate = useNavigation();
  const location = useLocation();
  const { timeZone } = useTimeZone();
  const { createBillingSessionAndRedirect } = useStripeCheckout();
  /*Expand the accordion to show the notice when user either has
   * 1. Failed payment
   * 2. Canceled their membership
   * 3. Fewer credits than needed for the billing period
   * 4. More credits than allowed to rollover at the end of their billing period after all expenses */
  const [expanded, setExpanded] = React.useState(
    subscriptionStatus === StripeSubscriptionStatus.PastDue ||
      cancelAtPeriodEnd ||
      availableCredits < numCreditsNeedForPeriod ||
      availableCredits - numCreditsNeedForPeriod >
        rolloverSubscriptionCreditLimit
  );

  const getNotice = React.useCallback(() => {
    if (cancelAtPeriodEnd) {
      return (
        <Typography variant="body2" sx={{ paddingTop: 10 }}>
          {t`Your membership is ending soon. Any unused credits will be lost, and recurring classes will be charged to your saved payment method.`}
        </Typography>
      );
    }
    if (subscriptionStatus === StripeSubscriptionStatus.PastDue) {
      return (
        <Typography variant="body2">
          {t(
            "Payment failed. Update your payment method by {{cancellationDate}} to keep your credits. Once processed, your monthly credits will be added.",
            {
              cancellationDate: formatDate(
                dayjs(nextSubscriptionStartDate).add(7, "days").toDate(),
                timeZone,
                true
              )
            }
          )}
        </Typography>
      );
    }
    if (
      availableCredits - numCreditsNeedForPeriod >
      rolloverSubscriptionCreditLimit
    ) {
      return (
        <Typography variant="body2" sx={{ paddingTop: 10 }}>
          {t`You have exceeded your`}
          &nbsp;
          {t(
            "{{rolloverSubscriptionCreditLimit}}-credit rollover limit. Please use your excess credits before renewal.",
            {
              rolloverSubscriptionCreditLimit
            }
          )}
        </Typography>
      );
    }

    if (availableCredits < numCreditsNeedForPeriod) {
      return (
        <Typography variant="body2" sx={{ paddingTop: 10 }}>
          {t`Low on credits. Any remaining balance will be charged to your saved payment method. `}
        </Typography>
      );
    }
    return null;
  }, [
    cancelAtPeriodEnd,
    subscriptionStatus,
    availableCredits,
    numCreditsNeedForPeriod,
    rolloverSubscriptionCreditLimit,
    t,
    nextSubscriptionStartDate,
    timeZone
  ]);

  const getNoticeIcon = React.useCallback(() => {
    if (
      cancelAtPeriodEnd ||
      availableCredits - numCreditsNeedForPeriod >
        rolloverSubscriptionCreditLimit ||
      subscriptionStatus === StripeSubscriptionStatus.PastDue
    ) {
      return (
        <Chip
          sx={{
            height: 16,
            width: 16
          }}
          size="small"
          color="error"
          label={<Icon icon={faExclamationTriangle} sx={{ fontSize: 12 }} />}
        />
      );
    }

    if (availableCredits < numCreditsNeedForPeriod) {
      return (
        <Chip
          sx={{
            height: 16,
            width: 16
          }}
          size="small"
          color="warning"
          label={<Icon icon={faInfoCircle} sx={{ fontSize: 12 }} />}
        />
      );
    }
    return null;
  }, [
    availableCredits,
    cancelAtPeriodEnd,
    subscriptionStatus,
    numCreditsNeedForPeriod,
    rolloverSubscriptionCreditLimit
  ]);

  const getCTA = React.useCallback(() => {
    if (cancelAtPeriodEnd) {
      return (
        <TrackedButton
          size="small"
          variant="link"
          trackingName="subscription_credit_nav_item_rejoin_membership_btn"
          sx={{
            paddingTop: 10,
            marginLeft: "auto"
          }}
          onClick={() => {
            navigate(joinMembershipPath());
          }}
        >
          {t("Rejoin Membership")}
        </TrackedButton>
      );
    }
    if (subscriptionStatus === StripeSubscriptionStatus.PastDue) {
      return (
        <TrackedButton
          size="small"
          variant="link"
          trackingName="subscription_credit_nav_item_update_payment_method_btn"
          sx={{
            paddingTop: 10,
            marginLeft: "auto"
          }}
          disabled={Env.IS_READ_ONLY_MODE}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            createBillingSessionAndRedirect({
              newTab: true,
              onReturnPath: location.pathname
            });
          }}
        >
          {t("Update Payment Method")}
          <Icon icon={faArrowUpRightFromSquare} sx={{ marginLeft: "0.25em" }} />
        </TrackedButton>
      );
    }
    if (
      availableCredits - numCreditsNeedForPeriod >
      rolloverSubscriptionCreditLimit
    ) {
      return (
        <TrackedButton
          size="small"
          variant="link"
          trackingName="subscription_credit_nav_item_add_more_credits_btn"
          sx={{
            paddingTop: 10,
            marginLeft: "auto"
          }}
          onClick={() => {
            navigate(transactionsPath());
          }}
        >
          {t("Review Your Balance")}
        </TrackedButton>
      );
    }

    return (
      <TrackedButton
        size="small"
        variant="link"
        trackingName="subscription_credit_nav_item_add_more_credits_btn"
        sx={{
          paddingTop: 10,
          marginLeft: "auto"
        }}
        disabled={Env.IS_READ_ONLY_MODE}
        onClick={() => {
          onAddMoreCredits();
        }}
      >
        {t("Get More Credits")}
      </TrackedButton>
    );
  }, [
    cancelAtPeriodEnd,
    subscriptionStatus,
    availableCredits,
    numCreditsNeedForPeriod,
    rolloverSubscriptionCreditLimit,
    t,
    navigate,
    createBillingSessionAndRedirect,
    location.pathname,
    onAddMoreCredits
  ]);

  if (!upComingChargesByWeek) {
    return null;
  }

  return (
    <Box sx={{ ...containerSx }}>
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          border: "none",
          width: "100%",
          backgroundColor: "transparent",
          "&:before": { display: "none" }
        }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary sx={{ padding: "0", flexDirection: "row-reverse" }}>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography as="span" variant="body2" sx={{ marginLeft: 4 }}>
              {t("Credits needed for this period:")}
            </Typography>
            &nbsp;
            <Typography as="span" variant="body2" sx={{ fontWeight: "bold" }}>
              {t("{{numCreditsNeedForPeriod}} credits", {
                numCreditsNeedForPeriod
              })}
            </Typography>
            &nbsp;
            {getNoticeIcon()}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ marginTop: -20, paddingLeft: 20 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            {getNotice()}
            <Box sx={{ paddingTop: 10 }}>
              {upComingChargesByWeek.map((charge, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16,
                    paddingY: 8,
                    borderTop: "1px solid",
                    borderBottom:
                      index === upComingChargesByWeek.length - 1
                        ? "1px solid"
                        : "none",
                    borderColor: "grey.300",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "left", flex: 1.5 }}
                  >
                    {t("Week of {{chargeDate}}", {
                      chargeDate: dayjs(charge.date).format("MMM D")
                    })}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "left", flex: 1 }}
                  >
                    {charge.numEnrollments}{" "}
                    {charge.numEnrollments === 1 ? t`class` : t`classes`}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ ml: "auto", textAlign: "right", flex: 1 }}
                  >
                    {charge.priceCredits} {t`credits`}
                  </Typography>
                </Box>
              ))}
            </Box>
            {getCTA()}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
