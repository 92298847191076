import {
  ACCESS_JWT_EXPIRE_EARLY_MS,
  ACCESS_JWT_MIN_LIFETIME_MS,
  Auth,
  Roles,
  decodeToken,
  hasExpired,
  requireRoles
} from "@outschool/auth-shared";
import { ErrorMessages } from "@outschool/errors";
import { OrganizationType } from "@outschool/gql-backend-generated";
import {
  addParamsToUrl,
  browseRootPath,
  outschoolForSchoolsUrl,
  removeParamsFromUrl
} from "@outschool/routes";
import { Integration } from "@outschool/ui-analytics";
import { ApolloClient } from "@outschool/ui-apollo";
import {
  APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME,
  AuthTrigger,
  AuthTriggerActionTypes,
  currentUserQuery,
  getAuthStrategy,
  getPostLoginPath,
  persistPostLoginPath
} from "@outschool/ui-auth";
import { switchToLearnerModeWithToken } from "@outschool/ui-components-website";
import { UserFacingError, isApolloError } from "@outschool/ui-utils";
import * as H from "history";

import * as Env from "../../shared/Env";
import * as Routes from "../../shared/Routes";
import ActionType from "../actions/ActionType";
import AppDispatcher from "../lib/AppDispatcher";
import { updateAttributionForCurrentUser } from "../lib/Attribution";
import { setGlobalUserForSentry } from "../lib/sentry";
import {
  changeLearnerPasswordMutation,
  changePasswordMutation,
  loginMutation
} from "../queries/AuthenticationQueries";
import BaseStore from "./BaseStore";

import type {
  AdSettings,
  AnalyticsInterface,
  SendIdentify,
  SendTrackEvent
} from "@outschool/ui-analytics";

declare global {
  interface Window {
    Intercom?: (action: string, options?: { [key: string]: unknown }) => void;
  }
}

export const SESSION_TOKEN_LOCAL_STORAGE_NAME = "jwt";

export type RouteOnEnterFunction = (
  location: H.Location,
  roles?: Roles[]
) => void | string | undefined;

/**
 * The single store that represents application state.
 */
export default class AppState extends BaseStore {
  appDispatcher: AppDispatcher;
  history: H.History;
  localStorage: undefined | null | Storage;
  apolloClient!: ApolloClient<{}>;
  _getTokens:
    | undefined
    | (() => {
        sessionToken: string;
        refreshToken: string;
        decodedToken: Auth;
      });
  _setTokens: undefined | ((token: string, x: string) => unknown);
  _getAuthData:
    | undefined
    | (() => {
        isLoggedIn: boolean;
        isLeader: boolean;
        isAdmin: boolean;
        adminForOrganizationType: unknown;
      });
  _refreshTokenRepeaterTimeout: null | ReturnType<typeof setTimeout>;
  _logoutCurrentUser: undefined | ((e?: unknown) => unknown);
  _currentUserHasLoaded: undefined | boolean;
  _setTopNotice: undefined | ((msg: string) => void);
  _setTimedTopNotice: undefined | ((msg: string) => void);
  _handleLoggedIn: undefined | (() => Promise<void>);
  changePasswordError: unknown;
  postLoginPath!: string;
  postLoginAction: undefined | { actionType: string };
  intercomFailedToLoad: unknown;
  _currentUserIsLoading: undefined | boolean;
  currentUserHasAccount: undefined | boolean;
  _adSettings: undefined | AdSettings;
  _analytics: undefined | AnalyticsInterface;
  _identify: undefined | SendIdentify;
  _trackEvent: undefined | SendTrackEvent;
  _hasInitiatizedUserData: boolean = false;
  _refreshSessionToken: undefined | (() => Promise<void>);

  /**
   * Initialize application state.
   */
  constructor(history: H.History, localStorage: Storage | null) {
    super();
    this.appDispatcher = new AppDispatcher();
    this.history = history;

    // Infrastructure
    this.subscribe(this.appDispatcher, () => this._handleAction.bind(this));
    this.localStorage = localStorage;
    this._topNoticeFailureHandler = this._topNoticeFailureHandler.bind(this);
    this.setHandleLoggedIn = this.setHandleLoggedIn.bind(this);
    this.setRefreshSessionToken = this.setRefreshSessionToken.bind(this);
    this._refreshTokenRepeaterTimeout = null;

    if (!Env.isTest) {
      console.log(`Client version is ${Env.clientVersion}`);
    }

    if (!Env.isNode) {
      this.appDispatcher.dispatch(ActionType.Webapp.CHECK_INTERCOM);
    }
  }

  setApolloClient(apolloClient: $TSFixMe) {
    this.apolloClient = apolloClient;
  }

  setHandleLoggedIn(handleLoggedIn: () => Promise<void>) {
    this._handleLoggedIn = handleLoggedIn;
  }

  setAuth({ getTokens, setTokens, getAuthData }: $TSFixMe) {
    this._getTokens = getTokens;
    this._setTokens = setTokens;
    this._getAuthData = getAuthData;
  }

  setLogoutCurrentUser(logoutCurrentUser: $TSFixMe) {
    this._logoutCurrentUser = logoutCurrentUser;
  }

  setRefreshSessionToken(refreshSessionToken: $TSFixMe) {
    this._refreshSessionToken = refreshSessionToken;
  }

  setAnalytics(
    analytics: AnalyticsInterface,
    identify: SendIdentify,
    trackEvent: SendTrackEvent,
    adSettings: AdSettings
  ) {
    this._analytics = analytics;
    this._identify = identify;
    this._trackEvent = trackEvent;
    this._adSettings = adSettings;
  }

  static RequiredFieldsForInitUserData: Array<keyof AppState> = [
    // analytics
    "_analytics",
    "_identify",
    "_trackEvent",
    "_adSettings",
    // auth
    "_getTokens",
    "_setTokens",
    "_getAuthData",
    // top notice
    "_setTopNotice",
    "_setTimedTopNotice",
    // apollo stuff
    "apolloClient",
    "_logoutCurrentUser",
    "_refreshSessionToken"
  ];

  /**
   * Functions that can be assigned to routes' onEnter.
   * These functions should return a path or URL to redirect to.
   */
  static RouteOnEnterHandlers: Array<keyof AppState> = [
    "requireAuth",
    "requireNonLoggedIn",
    "requireRole",
    "requireExplicitRole",
    "requireAdmin",
    "requireAdminForSchool",
    "requireAdminForCompany",
    "requireAdminForPartner"
  ];

  initUserData() {
    // check if we should initialize or not
    if (AppState.RequiredFieldsForInitUserData.some(field => !this[field])) {
      return;
    }
    // gate to ensure we don't initialize more than once
    if (this._hasInitiatizedUserData) {
      return;
    }
    this._hasInitiatizedUserData = true;
    // User data.

    const sessionToken = this._getTokens?.().sessionToken;

    const refreshToken = this._getTokens?.().refreshToken;

    if (
      sessionToken &&
      refreshToken &&
      hasExpired(sessionToken) &&
      hasExpired(refreshToken)
    ) {
      this._logoutCurrentUser?.({
        reason: "initUserData: Both session and refresh token have expired"
      });
      this._currentUserHasLoaded = true;
    } else {
      if (sessionToken && window.location.pathname !== Routes.logoutPath()) {
        this.appDispatcher.dispatch(ActionType.User.REFRESH);
      } else if (!sessionToken) {
        this._currentUserHasLoaded = true;
      }
    }
  }

  /**
   * Action dispatch.
   * Invoked when the dispatcher sends an action our way.
   */
  async _handleAction(action: $TSFixMe) {
    // @ts-ignore
    if (this[action.actionType]) {
      // @ts-ignore
      return this[action.actionType](action);
    } else if (
      Object.values(AuthTriggerActionTypes).includes(action.actionType)
    ) {
      if (this._getAuthData?.().isLoggedIn) {
        await this._handleLoggedIn?.();
      }
    } else {
      console.log("Unknown action", action.actionType);
    }
  }

  setTopNoticeSetter(setTopNotice: $TSFixMe) {
    this._setTopNotice = setTopNotice;
  }

  setTimedTopNoticeSetter(setTimedTopNotice: $TSFixMe) {
    this._setTimedTopNotice = setTimedTopNotice;
  }

  _topNoticeFailureHandler(error: $TSFixMe) {
    if (Env.isDevelopment) {
      console.log("Top notice failure handler", error);
    }

    let message;
    if (isApolloError(error)) {
      message = new UserFacingError(error).toString();
    } else {
      message =
        error && error.message ? error.message : error && error.toString();
    }

    if (
      message === ErrorMessages.SESSION_HAS_EXPIRED ||
      message === ErrorMessages.USER_ACCOUNT_SUSPENDED ||
      message === ErrorMessages.USER_ACCOUNT_NOT_FOUND ||
      message === ErrorMessages.USER_ACCOUNT_LOCKED ||
      message === ErrorMessages.USER_ACCOUNT_LOCKED_INACTIVE ||
      message === ErrorMessages.INCORRECT_INFO ||
      message === ErrorMessages.CANT_REFRESH_TOKEN
    ) {
      console.log("Got Session has expired error. Logging out");

      this._logoutCurrentUser?.({
        reason: "Topnotice: Error from backend: " + message
      });
      persistPostLoginPath(window.location.pathname + window.location.search);
      this.history.push(Routes.loginPath());

      this._setTopNotice?.(
        "We have logged you out to protect your account. Please log back in to continue."
      );
      return;
    }

    this._setTopNotice?.(message);
    this.emitChange();
  }

  /****************************************************************************
   * ACTION HANDLERS
   *
   * Action handler methods, named after the actions themselves.
   */

  // Params: userUid, token, newPassword
  async [ActionType.User.CHANGE_PASSWORD](action: $TSFixMe) {
    delete this.changePasswordError;

    try {
      const { data } = await this.apolloClient.mutate({
        mutation: action.userUid
          ? changePasswordMutation
          : changeLearnerPasswordMutation,
        variables: {
          uid: action.userUid || action.learnerUid,
          token: action.token,
          password: action.newPassword
        }
      });

      if (!action.userUid) {
        const { transferToken } = data.changeLearnerPassword;

        this._logoutCurrentUser?.({
          reason: "ChangePassword: Switching to learner mode"
        });
        switchToLearnerModeWithToken({
          isReviewApp: Env.isReviewApp,
          transferToken,
          isLearnerLogin: true
        });
        return;
      }
      const { sessionToken, refreshToken } = data.changePassword;

      this._setTokens?.(sessionToken, refreshToken);
      this._trackEvent?.(ActionType.User.CHANGE_PASSWORD + "_COMPLETED");
      await this._refreshCurrentUser?.();

      this._setTimedTopNotice?.(
        "You have successfully changed your password. Thanks!"
      );

      this.history.push(Routes.accountPath(this._getAuthData?.().isLeader));
    } catch (e) {
      this._setTopNotice?.(new UserFacingError(e).toString?.());
      this.emitChange();
    }
  }

  // Params: email, password
  async [ActionType.User.LOGIN](action: $TSFixMe) {
    const { email, password, postLoginPath, postLoginOption } = action;
    // set this.postLoginPath for redirectAfterLogin
    if (!!postLoginPath) {
      this.postLoginPath = postLoginPath;
    }

    try {
      const { data } = await this.apolloClient.mutate({
        mutation: loginMutation,
        variables: {
          emailOrUsername: email,
          password,
          postLoginOption
        }
      });
      const decodedToken = decodeToken(data.login.sessionToken);
      //@ts-ignore-error Accessing an occasional property
      if (decodedToken.isLearnerTransfer) {
        this._logoutCurrentUser?.({
          reason: "Login: Switching to learner mode"
        });
        switchToLearnerModeWithToken({
          isReviewApp: Env.isReviewApp,
          transferToken: data.login.sessionToken,
          isLearnerLogin: true
        });
        return;
      }
      this._loginCurrentUser(data.login.sessionToken, data.login.refreshToken);
      this.emitChange();
      this._startRefreshSessionTokenRepeater?.();
      const currentUser = await this._refreshCurrentUser?.();
      const isSellerOrg = currentUser && currentUser.latestSellerOrgApplication;

      this.redirectAfterLogin(
        Routes.defaultPostLoginPath(
          currentUser,

          this._getAuthData?.().isLeader,
          isSellerOrg
        )
      );
    } catch (e) {
      this._setTopNotice?.(new UserFacingError(e).toString?.());
      this.emitChange();
    }
  }

  async [ActionType.User.LOGGED_IN](action: $TSFixMe) {
    this._loginCurrentUser(action.sessionToken, action.refreshToken);
    await this._refreshCurrentUser?.();
    this.emitChange();
    this._startRefreshSessionTokenRepeater?.();
  }

  async [ActionType.User.REFRESH]() {
    if (!this._getTokens?.().sessionToken) {
      this._currentUserHasLoaded = true;
      return;
    }

    try {
      await this._refreshSessionToken?.();
    } catch (e) {
      this._currentUserHasLoaded = true;
      this._topNoticeFailureHandler(e);
      return;
    }

    try {
      await this._refreshCurrentUser?.();
    } catch (e) {
      this._topNoticeFailureHandler(e);
      return;
    }

    if (this.localStorage) {
      const { strategy, loginStarted, loginRedirect } = getAuthStrategy();
      const started = this.localStorage.getItem(loginStarted);
      const redirect = this.localStorage.getItem(loginRedirect);
      if ((!!strategy && started) || redirect) {
        console.log("OAUTH2 Flow");
      } else {
        try {
          const action = JSON.parse(
            // @ts-ignore TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            this.localStorage.getItem(
              APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME
            )
          );
          if (action) {
            this.postLoginAction = action;
            this.redirectAfterLogin();
          }
        } catch (e) {
          if (e instanceof SyntaxError) {
          } else {
            throw e;
          }
        }

        this.localStorage.removeItem(
          APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME
        );
      }
    }
    this.emitChange();
    this._startRefreshSessionTokenRepeater?.();
  }

  [ActionType.User.REDIRECT_TO_LEARNER_APP](action: $TSFixMe) {
    const { authRequirement, transferToken, learnerAppUrl, isLearnerLogin } =
      action;
    if (authRequirement) {
      this._logoutCurrentUser?.({
        reason: "RedirectToLearner: Switching to learner mode"
      });
    }
    switchToLearnerModeWithToken({
      isReviewApp: Env.isReviewApp,
      transferToken,
      learnerAppUrl,
      isLearnerLogin
    });
  }

  async [ActionType.User.CURRENT_USER_UPDATED](action: $TSFixMe) {
    await this._updateCurrentUser(action.property, action?.value);
    this.emitChange();
  }

  [ActionType.Webapp.CHECK_INTERCOM]() {
    this.intercomFailedToLoad = true;
    setTimeout(() => {
      this.intercomFailedToLoad = !window?.Intercom;
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get("intercom") === "true") {
        if (this.intercomFailedToLoad) {
          this.emitChange();
        } else {
          window?.Intercom?.("show");
        }
      }
    }, 5000);
  }

  redirectAfterLogin(defaultPath?: $TSFixMe) {
    const { loginStarted, loginRedirect } = getAuthStrategy();
    if (
      !this.postLoginAction &&
      this.localStorage &&
      this.localStorage.getItem(loginStarted)
    ) {
      try {
        this.postLoginAction = JSON.parse(
          // @ts-ignore TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          this.localStorage.getItem(loginStarted)
        );
      } catch (e) {
        console.log("does not have a defined post login action");
      }
      this.localStorage.removeItem(loginStarted);
    }

    if (
      !this.postLoginPath &&
      this.localStorage &&
      this.localStorage.getItem(loginRedirect)
    ) {
      try {
        this.postLoginPath = JSON.parse(
          // @ts-ignore TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          this.localStorage.getItem(loginRedirect)
        );
      } catch (e) {
        console.log("Did not have a post login path set");
      }
      this.localStorage.removeItem(loginRedirect);
    }

    if (this.postLoginAction) {
      this.appDispatcher.dispatch(
        this.postLoginAction.actionType,
        this.postLoginAction
      );
    } else if (this.localStorage) {
      try {
        const action = JSON.parse(
          // @ts-ignore TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          this.localStorage.getItem(
            APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME
          )
        );
        if (action) {
          this.postLoginAction = action;
          this.appDispatcher.dispatch(
            // @ts-ignore TS(2532): Object is possibly 'undefined'.
            this.postLoginAction.actionType,
            this.postLoginAction
          );
        }
      } catch (e) {
        if (e instanceof SyntaxError) {
        } else {
          throw e;
        }
      }

      this.localStorage.removeItem(
        APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME
      );
    }
    if (this.postLoginPath || defaultPath) {
      this.history.push(this.postLoginPath || defaultPath);
    }
    // @ts-ignore TS(2790): The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
    delete this.postLoginPath;
    delete this.postLoginAction;
  }

  /****************************************************************************
   * STATE ACCESSORS
   */

  _startRefreshSessionTokenRepeater() {
    if (Env.isTest || !!Env.REFRESH_SESSION_TOKEN_REPEATER_DISABLED) {
      return;
    }

    const getTokens = this._getTokens;

    // Set the next refresh to N seconds before the token expires.
    const decodedToken = getTokens?.()?.decodedToken;
    const expiresAtMs = (decodedToken?.exp ?? 0) * 1000;
    const expiresInMs = expiresAtMs - Date.now();
    const nextRefreshMs =
      Math.max(expiresInMs, ACCESS_JWT_MIN_LIFETIME_MS) -
      ACCESS_JWT_EXPIRE_EARLY_MS;

    if (this._refreshTokenRepeaterTimeout) {
      clearTimeout(this._refreshTokenRepeaterTimeout);
    }

    this._refreshTokenRepeaterTimeout = setTimeout(() => {
      this._refreshTokenRepeaterTimeout = null;
      if (!getTokens?.()?.decodedToken) {
        return;
      }
      this._refreshSessionToken?.().then(() => {
        this._startRefreshSessionTokenRepeater?.();
      }, this._topNoticeFailureHandler);
    }, nextRefreshMs);
  }

  async _refreshCurrentUser() {
    this._currentUserIsLoading = true;
    this.emitChange();

    try {
      const { data } = await this.apolloClient.query({
        query: currentUserQuery,
        fetchPolicy: "network-only"
      });
      const currentUser = data?.currentUser;
      if (currentUser) {
        setGlobalUserForSentry(currentUser.uid);
      }

      /*
       * identify is first called here on page load for users who are logged in OR
       * immediately after a user login.
       *
       * For logged-out users see routes/AppContainer.tsx.
       */
      this._identify?.(currentUser?.uid, currentUser, undefined, () => {
        if (currentUser) {
          // @ts-ignore TS(2345): Argument of type 'AnalyticsInterface | undefined' ... Remove this comment to see the full error message
          updateAttributionForCurrentUser(this._analytics, currentUser);

          /*
           * If the user has selected DNSMPI in the past as indicated by
           * their user data, but the preference is not set locally in ad
           * settings, update ad settings and reload the page to unload
           * any ad related integrations from their anonymous browsing
           * session.
           */
          if (
            !!this.localStorage &&
            currentUser.do_not_sell_my_personal_info &&
            // @ts-ignore TS(2532): Object is possibly 'undefined'.
            !this._adSettings.shouldNotSellPersonalInfo
          ) {
            // @ts-ignore TS(2532): Object is possibly 'undefined'.
            this._adSettings.updateDoNotSellMyPersonalInfo(true);

            this._trackEvent?.("dnsmpi_mismatch", undefined, undefined, () => {
              window.location.reload();
            });
          }
        }
      });

      return currentUser;
    } finally {
      this._currentUserHasLoaded = true;
      this._currentUserIsLoading = false;
      this.emitChange();
    }
  }

  async _updateCurrentUser(property: $TSFixMe, value: $TSFixMe) {
    await this._refreshCurrentUser?.();
    if (property) {
      const payload = value ? { property, value } : { property };
      this._trackEvent?.(ActionType.User.UPDATE, payload);
      this._trackEvent?.("Updated preference", payload, {
        integrations: {
          [Integration.Intercom]: true
        }
      });
    }
  }

  get currentUserIsLoading() {
    return !!this._currentUserIsLoading;
  }

  get currentUserHasLoaded() {
    return !!this._currentUserHasLoaded;
  }

  _loginCurrentUser(sessionToken: $TSFixMe, refreshToken?: $TSFixMe) {
    this._setTokens?.(sessionToken, refreshToken);
  }

  requireAuth: RouteOnEnterFunction = location => {
    if (!this._getAuthData?.().isLoggedIn) {
      // Investigate auth issue when joining classes
      try {
        if (location?.pathname.match(/\/sections\/.*\/join/)) {
          OsPlatform.captureError(
            new Error("No auth data when joining a class"),
            {
              extra: {
                hasGetAuthDataFn: Boolean(this._getAuthData),
                authData: this._getAuthData?.()
              }
            }
          );
        }
      } catch (err) {
        console.log("failed to log auth issue");
      }

      persistPostLoginPath(location.pathname + location.search);
      return Routes.loginPath();
    }
    return undefined;
  };

  getEnrollPath = (location: $TSFixMe) => {
    if (!this._getAuthData?.().isLoggedIn) {
      const fullPath = location.pathname + location.search;
      // NotEnrolledView component triggers a redirect with the additional
      // URL params, we want to get rid of them post login
      persistPostLoginPath(
        removeParamsFromUrl(fullPath, ["authTrigger", "signup"])
      );
      return addParamsToUrl(fullPath, {
        authTrigger: AuthTrigger.ENROLL,
        signup: true,
        skipLearners: true
      });
    }
    return undefined;
  };

  getWaitlistPath = (location: $TSFixMe) => {
    if (!this._getAuthData?.().isLoggedIn) {
      const fullPath = location.pathname + location.search;
      // NotEnrolledView component triggers a redirect with the additional
      // URL params, we want to get rid of them post login
      persistPostLoginPath(
        removeParamsFromUrl(fullPath, ["authTrigger", "signup"])
      );
      return addParamsToUrl(fullPath, {
        authTrigger: AuthTrigger.WAITLIST,
        signup: true,
        skipLearners: true
      });
    }
    return undefined;
  };

  requireAdmin: RouteOnEnterFunction = () => {
    if (!this._getAuthData?.().isAdmin) {
      return browseRootPath();
    }
    return undefined;
  };

  requireNonLoggedIn: RouteOnEnterFunction = (location: H.Location) => {
    if (this._getAuthData?.().isLoggedIn) {
      const queryParam = new URLSearchParams(location.search);

      if (queryParam?.get("redirect")) {
        return queryParam.get("redirect") as string;
      }

      if (getPostLoginPath()) {
        return getPostLoginPath() as string;
      }
      return Routes.parentSettingsPath();
    }
    return undefined;
  };

  requireRole: RouteOnEnterFunction = (_location, roles) => {
    if (
      !this._getTokens?.().decodedToken ||
      !requireRoles(this._getTokens?.().decodedToken, roles || [], true)
    ) {
      return browseRootPath();
    }
    return undefined;
  };

  requireExplicitRole: RouteOnEnterFunction = (_location, roles) => {
    if (
      !this._getTokens?.().decodedToken ||
      !requireRoles(this._getTokens?.().decodedToken, roles || [], false)
    ) {
      return browseRootPath();
    }
    return undefined;
  };

  requireAdminForOrganization = (
    organizationTypes: $TSFixMe,
    loggedOutRedirectRoute?: $TSFixMe
  ) => {
    const { adminForOrganizationType, isLoggedIn } =
      this._getAuthData?.() || {};
    if (loggedOutRedirectRoute && !isLoggedIn) {
      return loggedOutRedirectRoute;
    } else if (!organizationTypes.includes(adminForOrganizationType)) {
      return browseRootPath();
    }
    return undefined;
  };

  requireAdminForSchool: RouteOnEnterFunction = () => {
    this.requireAdminForOrganization(
      [
        OrganizationType.CharterSchool,
        OrganizationType.School,
        OrganizationType.EsaOrMicrogrant
      ],
      outschoolForSchoolsUrl()
    );
  };

  requireAdminForCompany: RouteOnEnterFunction = () => {
    this.requireAdminForOrganization(
      [OrganizationType.Company],
      Routes.outschoolForEmployersUrl()
    );
  };

  requireAdminForPartner: RouteOnEnterFunction = () => {
    this.requireAdminForOrganization([
      OrganizationType.CommunityPartner,
      OrganizationType.OrgCommunityPartner
    ]);
  };
}
