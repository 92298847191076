import { Alert, Box } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { UserMode } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import { useAiSummaryPolling } from "../../hooks/useAiSummaryPolling";
import useExperiment from "../../hooks/useExperiment";
import { useUserMode } from "../../lib/UserMode";

export function AiSummaryBannerExp() {
  const { variant: recapVariant } = useExperiment("AiMeetingRecaps");
  const recapFlag = useFeatureFlag("ai-meeting-recaps-frontend");
  const { isLoggedIn } = useSession();
  const userMode = useUserMode();
  const isEducatorMode = userMode === UserMode.Educator;
  const showRecap =
    recapFlag && recapVariant === "treatment" && isLoggedIn && isEducatorMode;

  if (!showRecap) {
    return null;
  }

  return <AiSummaryBanner />;
}

export function AiSummaryBanner() {
  const { t } = useTranslation("client\\components\\banners\\AiSummaryBanner");
  const { visibleSection, handleDismiss } = useAiSummaryPolling();
  const Link = useLinkComponent();

  if (!visibleSection) {
    return null;
  }

  return (
    <Box flex justifyContent="center" sx={{ backgroundColor: "#CFE6FB" }}>
      <Alert
        severity="info"
        onClose={handleDismiss}
        sx={{
          border: "none",
          borderRadius: 0,
          maxWidth: 1255,
          paddingX: 0,
          marginX: 14
        }}
      >
        <span>
          <Trans t={t}>
            <Link
              to={`/classroom/${visibleSection}`}
              onClick={handleDismiss}
              style={{
                textDecoration: "underline",
                color: "inherit",
                fontWeight: "500"
              }}
            >
              Click here
            </Link>{" "}
            to review the AI-generated parent class update
          </Trans>
        </span>
      </Alert>
    </Box>
  );
}
