import { MenuItem, Typography } from "@outschool/backpack";
import React from "react";

type SelectionMenuItemProps = {
  children: React.ReactNode;
  onClick: () => void;
};
export const SelectionMenuItem = ({
  children,
  onClick
}: SelectionMenuItemProps) => {
  return (
    <MenuItem onClick={onClick}>
      <Typography
        variant="subtitle2"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {children}
      </Typography>
    </MenuItem>
  );
};
