import { Roles } from "@outschool/auth-shared";
import { lazyWithRetry } from "@outschool/ui-utils";
import { RedirectProps, RouteProps } from "react-router-dom";

import AppState from "../stores/AppState";
import SellerOrgCredentialsPage from "./SellerOrgCredentialsPage";

/*
  Here we define the routes for our app.
  There are currently the following groupings of routes:
    - ADMIN
    - CLUB
    - LOGGED IN
    - GIFT CARD
    - PROFILE
    - RARE (This section uses separate `webpackChunkName`'s for each page, as we do not benefit from grouping these divergent pages into one chunk)
    - SELLER ORG
    - STANDALONE PAGES
    - TEACHER
  Each grouping, except `RARE` uses the same `webpackChunkName` to provide a grouping of related pages into one JS chunk.
  Please try to keep things alphabetical!
*/

/*
 * STANDALONE PAGES
 */

const ActivityDetailPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "activityDetailPage" */ "./ActivityDetailPage")
);
const SearchPage = lazyWithRetry(
  () => import(/* webpackChunkName: "searchPage" */ "./SearchPage")
);
const OnlineClassesPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "onlineClassesPage" */ "./OnlineClassesPage")
);

const PaidCategoryLandingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "paidCategoryLandingPage" */ "./PaidCategoryLandingPage"
    )
);
const TutoringPage = lazyWithRetry(
  () => import(/* webpackChunkName: "TutoringPage" */ "./TutoringPage")
);
const OnboardingQuizPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "OnboardingQuizPage" */ "./OnboardingQuizPage")
);
const PitchPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PitchPage" */ "./PitchPage")
);
const ChangePasswordPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "changePasswordPage" */ "./ChangePasswordPage")
);
const ChangePinPage = lazyWithRetry(
  () => import(/* webpackChunkName: "changePinPage" */ "./ChangePinPage")
);
const ConfirmEmailPage = lazyWithRetry(
  // @ts-ignore
  () => import(/* webpackChunkName: "confirmEmailPage" */ "./ConfirmEmailPage")
);
const ConfirmLearnerEmailPage = lazyWithRetry(
  () =>
    import(
      // @ts-ignore
      /* webpackChunkName: "confirmLearnerEmailPage" */ "./ConfirmLearnerEmailPage"
    )
);
const Home = lazyWithRetry(
  () => import(/* webpackChunkName: "home" */ "./Home")
);
const ParentHome = lazyWithRetry(
  () => import(/* webpackChunkName: "parentHome" */ "./ParentHome")
);
const JoinParentAccount = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "joinParentAccount" */ "./JoinParentAccount")
);
const JoinParentAccountWithApple = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "joinParentAccountWithApple" */ "./JoinParentAccountWithApple"
    )
);
const JoinParentAccountWithFacebook = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "joinParentAccountWithFacebook" */ "./JoinParentAccountWithFacebook"
    )
);
const JoinParentAccountWithGoogle = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "joinParentAccountWithGoogle" */ "./JoinParentAccountWithGoogle"
    )
);
const JoinTeacherAccount = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "joinTeacherAccount" */ "./JoinTeacherAccount")
);
const KansasKeepVerificationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "KansasKeepVerificationPage" */ "./KansasKeepVerificationPage"
    )
);
const Login = lazyWithRetry(
  // @ts-ignore
  () => import(/* webpackChunkName: "login" */ "./Login")
);
const SectionJoin = lazyWithRetry(
  () => import(/* webpackChunkName: "sectionJoin" */ "./SectionJoin")
);
const Signup = lazyWithRetry(
  () => import(/* webpackChunkName: "signup" */ "./Signup")
);
const BundlePage = lazyWithRetry(
  () => import(/* webpackChunkName: "bundle" */ "./BundlePage")
);

/*
 * ADMIN PAGES
 */
const ActivityAdminPage = lazyWithRetry(
  // @ts-ignore
  () => import(/* webpackChunkName: "adminPages" */ "./ActivityAdmin")
);
const BulkCreateGiftCardsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./BulkCreateGiftCardsPage")
);
const BuyerOrgDashboard = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./BuyerOrgDashboard")
);
const CouponAdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./CouponAdminPage")
);
const CouponCreateAdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./CouponCreateAdminPage")
);
const ClubsLandingPage = lazyWithRetry(
  () => import(/* webpackChunkName: "rarePages" */ "./ClubsLandingPage")
);
const CouponViewAdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./CouponViewAdminPage")
);
const EmailPreviewList = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./EmailPreviewList")
);
const EmailPreviewPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./EmailPreview")
);
const GiftCardsAdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./GiftCardsAdminPage")
);
const OrganizationsAdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./OrganizationsAdmin")
);
const OrganizationsAdminCreatePage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "adminPages" */ "./OrganizationsAdminCreate")
);
const OrganizationsAdminEditPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./OrganizationsAdminEdit")
);
const PricingOffersAdminCreatePage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "adminPages" */ "./PricingOffersAdminCreate")
);
const PricingOffersAdminEditPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./PricingOffersAdminEdit")
);
const PricingOffersAdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./PricingOffersAdmin")
);
const ReviewClassesPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./ReviewClassesPage")
);
const ReviewTeacherPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./ReviewTeacherPage")
);
const ReviewTeachersPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./ReviewTeachersPage")
);
const SectionAdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./SectionAdmin")
);
const UserAdmin = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./UserAdmin")
);
const UserImportPage = lazyWithRetry(
  () => import(/* webpackChunkName: "adminPages" */ "./UserImport")
);
const LearnerVerificationAdminPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "adminPages" */ "./LearnerVerificationAdmin")
);

/*
 * CLUB PAGES
 */
const ClubHomePage = lazyWithRetry(
  () => import(/* webpackChunkName: "clubPages" */ "./Club")
);
const ClubPostPage = lazyWithRetry(
  () => import(/* webpackChunkName: "clubPages" */ "./ClubSinglePost")
);

/*
 * GIFT CARD PAGES
 */
const GiftCardBuyPage = lazyWithRetry(
  () => import(/* webpackChunkName: "giftCardPages" */ "./GiftCardBuyPage")
);
const GiftCardLandingPage = lazyWithRetry(
  () => import(/* webpackChunkName: "giftCardPages" */ "./GiftCardLandingPage")
);

const GiftCardPurchaseConfirmationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "giftCardPages" */ "./GiftCardPurchaseConfirmationPage"
    )
);
const GiftCardRedemptionPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "giftCardPages" */ "./GiftCardRedemptionPage")
);

/*
 * LOGGED IN PAGES
 */
const Classroom = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./Classroom")
);
const TeacherParentConversations = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./TeacherParentConversations"
    )
);
const TeacherLearnerConversations = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./TeacherLearnerConversations"
    )
);
const EmailSettings = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./EmailSettings")
);
const EnrolledClass = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./EnrolledClassPage")
);
const ReviewSubmissionPage = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./ReviewSubmissionPage")
);
const FollowedTeachers = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./FollowedTeachers")
);
const InvitePage = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./InvitePage")
);
const JoinParentLearners = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./JoinParentLearners")
);
const LearnerLoginSwitcher = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./LearnerLoginSwitcher")
);
const LearnerProfilePage = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./LearnerProfile")
);
const Logout = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./Logout")
);
const MergeAccountConfirmRequest = lazyWithRetry(
  () =>
    import(
      // @ts-ignore
      /* webpackChunkName: "loggedInPages" */ "./MergeAccountConfirmRequest"
    )
);
const RedirectAfterLogin = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./RedirectAfterLogin")
);
const MergeAccountRequest = lazyWithRetry(
  // @ts-ignore
  () => import(/* webpackChunkName: "loggedInPages" */ "./MergeAccountRequest")
);
const ParentSchedule = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./ParentSchedulePage")
);
const ShoppingCart = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./ShoppingCart")
);
const ShoppingCartCheckout = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./ShoppingCart/ShoppingCartCheckout"
    )
);

const TutorMatch = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./TutorMatch/TutorMatchPage"
    )
);
const ParentSettings = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./ParentSettingsPage")
);
const PrivateClassMessagesPage = lazyWithRetry(
  () =>
    // @ts-ignore
    import(/* webpackChunkName: "loggedInPages" */ "./PrivateClassMessagesPage")
);
const PostEnrollConfirmationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./PostEnrollConfirmationPage"
    )
);
const PostOrderConfirmationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./PostOrderConfirmationPage"
    )
);
const Receipt = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./Receipt")
);
const ParentAuthCode = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./ParentAuthCode")
);
const RedirectToLearnerModePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./RedirectToLearnerModePage"
    )
);
const ReferTeachersPage = lazyWithRetry(
  // @ts-ignore
  () => import(/* webpackChunkName: "loggedInPages" */ "./ReferTeachersPage")
);
const SavedClasses = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./SavedClasses")
);
const SavedSearches = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./SavedSearches")
);
const SinglePost = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./SinglePost")
);
const Teach = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./Teach")
);
const TeacherClassesPage = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./TeacherClassesPage")
);
const TeacherClassLinksPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "loggedInPages" */ "./TeacherClassLinksPage")
);
const TeacherClubsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./TeacherClubsPage")
);
const TeacherMarketing = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "teacherMarketingPage" */ "./TeacherMarketingPage"
    )
);
const TeacherApplication = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherApplication")
);
const Transactions = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./TransactionsPage")
);
const Transcripts = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./Transcripts")
);
const UnfollowedConfirmation = lazyWithRetry(
  () =>
    // @ts-ignore
    import(/* webpackChunkName: "loggedInPages" */ "./UnfollowedConfirmation")
);
const UnscheduledRequestConfirmation = lazyWithRetry(
  () =>
    import(
      // @ts-ignore
      /* webpackChunkName: "loggedInPages" */ "./UnscheduledRequestConfirmation"
    )
);
const UnsubscribedConfirmation = lazyWithRetry(
  () =>
    // @ts-ignore
    import(/* webpackChunkName: "loggedInPages" */ "./UnsubscribedConfirmation")
);
const ParentDashboard = lazyWithRetry(
  () => import(/* webpackChunkName: "loggedInPages" */ "./ParentDashboard")
);

/*
 * PROFILE PAGES
 */
const ParentProfile = lazyWithRetry(
  () => import(/* webpackChunkName: "profilePages" */ "./ParentProfile")
);
const TeacherProfile = lazyWithRetry(
  () => import(/* webpackChunkName: "profilePages" */ "./TeacherProfile")
);

/*
 * RARE PAGES
 */
const AboutPage = lazyWithRetry(
  () => import(/* webpackChunkName: "AboutPage" */ "./AboutPage")
);
const AcceptGiftEnrollmentPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AcceptGiftEnrollmentPage" */ "./AcceptGiftEnrollmentPage"
    )
);
const CareersPage = lazyWithRetry(
  () => import(/* webpackChunkName: "CareersPage" */ "./CareersPage")
);
const ConfirmOtherEmailsPage = lazyWithRetry(
  () =>
    import(
      // @ts-ignore
      /* webpackChunkName: "ConfirmOtherEmailsPage" */ "./ConfirmOtherEmailsPage"
    )
);
const PersonalizedAdSettingsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PersonalizedAdSettingsPage" */ "./PersonalizedAdSettingsPage"
    )
);
const MuxRecording = lazyWithRetry(
  () => import(/* webpackChunkName: "MuxRecording" */ "./MuxRecording")
);
const OrgsTermsPage = lazyWithRetry(
  // @ts-ignore
  () => import(/* webpackChunkName: "OrgsTermsPage" */ "./OrgsTermsPage")
);
const TrustAndSafetyPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "TrustAndSafetyPage" */ "./TrustAndSafetyPage")
);
const TeacherSchedulingTipsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TeacherSchedulingTipsPage" */ "./TeacherSchedulingTipsPage"
    )
);

/*
 * SELLER ORG PAGES
 */
const SellerOrgAgreementDownloadPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "sellerOrgPages" */ "./SellerOrgAgreementDownloadPage"
    )
);
const SellerOrgAgreementPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgAgreementPage")
);
const SellerOrgApplication = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgApplication")
);
const SellerOrgClassesPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgClassesPage")
);
const SellerOrgClassLinksPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgClassLinksPage")
);
const SellerOrgOwnerClubsPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgOwnerClubsPage")
);
const SellerOrgDashboard = lazyWithRetry(
  () => import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgDashboard")
);
const SellerOrgFeedbackPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgFeedbackPage")
);
const SellerOrgLandingPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgLandingPage")
);
const SellerOrgManage = lazyWithRetry(
  () => import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgManage")
);
const SellerOrgOperationsPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgOperationsPage")
);
const SellerOrgProfilePage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgProfilePage")
);
const SellerOrgMarketingPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgMarketingPage")
);
const SellerOrgSchedule = lazyWithRetry(
  () => import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgSchedule")
);
const SellerOrgSectionsPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgSectionsPage")
);
const SellerOrgStatsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "sellerOrgPages" */ "./SellerOrgStatsPage")
);

/*
 * PARTNER ORG PAGES
 */
const SchoolAdminLandingPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "partnerOrgPages" */ "./SchoolAdminLandingPage")
);
const SchoolAdminAnalytics = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "partnerOrgPages" */ "./SchoolAdminAnalytics")
);
const EmployerAdminLandingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "partnerOrgPages" */ "./EmployerAdminLandingPage"
    )
);
const EmployerAdminAnalytics = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "partnerOrgPages" */ "./EmployerAdminAnalytics")
);
const PartnerAdminLandingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "partnerOrgPages" */ "./PartnerAdminLandingPage"
    )
);
const PartnerAdminAnalytics = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "partnerOrgPages" */ "./PartnerAdminAnalytics")
);

/*
 * TEACHER PAGES
 */
const ActivityApproval = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./ActivityApproval")
);
const ActivityCreatePage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./ActivityCreatePage")
);
const ActivityEditPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./ActivityEditPage")
);
const ActivitySchedule = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./ActivitySchedule")
);
const ActivityPostsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./ActivityPostsPage")
);
const ActivityAvailabilityPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "teacherPages" */ "./ActivityAvailabilityPage")
);
const BundleCreatePage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./BundleCreatePage")
);
const ClubEditPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./ClubEditPage")
);
const ClubManageListing = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./ClubManageListing")
);
const LeaderSettings = lazyWithRetry(
  // @ts-ignore
  () => import(/* webpackChunkName: "teacherPages" */ "./LeaderSettings")
);
const OnboardTeachersPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./OnboardTeachersPage")
);
const OrgTeachersPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./OrgTeachersPage")
);
const SectionEdit = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./SectionEdit")
);

const SectionPostsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./SectionPostsPage")
);
const SectionSelfPacedManage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "teacherPages" */ "./SectionSelfPacedManage")
);
const SectionLearners = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./SectionLearners")
);
const SectionRecordings = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./SectionRecordings")
);

const SectionMeetings = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./SectionMeetings")
);

const SectionAiAssistant = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "teacherPages" */ "./SectionAiAssistantPage")
);

const SyllabusEditPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./SyllabusEditPage")
);

const SectionAssignments = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "teacherPages" */ "./SectionAssignmentsPage")
);

const TeacherAvailabilityPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "teacherPages" */ "./TeacherAvailabilityPage")
);
const TeacherDiscountsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherDiscountsPage")
);
const TeacherDashboard = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherDashboard")
);
const TeacherBroadcast = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherBroadcastPage")
);
const TeacherDiscountsCreatePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "teacherPages" */ "./TeacherDiscountsCreatePage"
    )
);
const TeacherDiscountsDetailsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "teacherPages" */ "./TeacherDiscountsDetailsPage"
    )
);
const TeacherFeedbackPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherFeedbackPage")
);
const TeacherCredentialsPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "teacherPages" */ "./TeacherCredentialsPage")
);
const TeacherProfessionalLearningPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "teacherPages" */ "./TeacherProfessionalLearningPage"
    )
);
const TeachInvitesPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeachInvitesPage")
);
const TeacherSchedule = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherSchedule")
);
const TeacherSectionsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherSectionsPage")
);
const TeacherStats = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./TeacherStatsPage")
);
const BundleEditPage = lazyWithRetry(
  () => import(/* webpackChunkName: "teacherPages" */ "./BundleEditPage")
);
const SectionToActivityRedirect = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "loggedInPages" */ "./SectionToActivityRedirect"
    )
);

const AfterStripeCheckout = lazyWithRetry(
  () => import(/* webpackChunkName: "subscriptions" */ "./AfterStripeCheckout")
);
const AutoplaySandbox = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "subscriptions" */ "./Subscriptions/pages/Autoplay"
    )
);

const JoinMembershipPage = lazyWithRetry(
  () => import(/* webpackChunkName: "subscriptions" */ "./JoinMembershipPage")
);

export type RouteDefinition = RouteProps &
  Required<Pick<RouteProps, "path">> & {
    redirect?: RedirectProps["to"];
    onEnter?: (typeof AppState.RouteOnEnterHandlers)[number];
    roles?: Roles[];
    isTranslated?: boolean;
    name?: string;
    category?: string;
  };

const ROUTES: RouteDefinition[] = [
  { path: "/", component: Home, name: "home", isTranslated: true },
  { path: "/parent", component: ParentHome },
  {
    path: "/redirect-from-learner",
    redirect: "/users/login"
  },
  {
    path: "/parent-auth-code/:authCode",
    component: ParentAuthCode
  },
  {
    path: "/after-stripe-checkout/:checkoutSessionId",
    component: AfterStripeCheckout,
    exact: true
  },
  {
    path: "/users/login",
    component: Login,
    onEnter: "requireNonLoggedIn",
    isTranslated: true
  },
  { path: "/users/logout", component: Logout, isTranslated: true },
  {
    path: "/user/:uid/confirm",
    component: ConfirmEmailPage,
    isTranslated: true
  },
  { path: "/learner/:uid/confirm", component: ConfirmLearnerEmailPage },
  {
    path: "/users/signup",
    component: Signup,
    onEnter: "requireNonLoggedIn",
    isTranslated: true
  },
  {
    path: "/join/account",
    component: JoinParentAccount,
    onEnter: "requireNonLoggedIn",
    isTranslated: true
  },
  {
    path: "/join/account/apple",
    component: JoinParentAccountWithApple,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/join/account/facebook",
    component: JoinParentAccountWithFacebook,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/join/account/google",
    component: JoinParentAccountWithGoogle,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/join/learners",
    component: JoinParentLearners,
    onEnter: "requireAuth",
    isTranslated: true
  },
  { path: "/intro", redirect: "/" },
  {
    path: "/giftcard",
    component: GiftCardLandingPage,
    isTranslated: true
  },
  {
    path: "/giftcard/buy/:defaultPriceInDollars?",
    component: GiftCardBuyPage,
    isTranslated: true
  },
  {
    path: "/giftcard/confirmation/:uid",
    component: GiftCardPurchaseConfirmationPage,
    isTranslated: true
  },
  {
    path: "/giftcard/redeem/:redemptionCode?",
    component: GiftCardRedemptionPage,
    isTranslated: true
  },
  {
    path: "/giftcard/:theme?",
    component: GiftCardLandingPage,
    isTranslated: true
  },
  { path: "/join/teacher/account", component: JoinTeacherAccount },
  { path: "/join/teacher/about", redirect: "/account/teacher" },
  { path: "/join/teacher/name", redirect: "/account/teacher" },
  { path: "/join/teacher/photo", redirect: "/account/teacher" },
  { path: "/join/teacher/availability", redirect: "/account/teacher" },
  {
    path: "/users/change_password",
    component: ChangePasswordPage,
    isTranslated: true
  },
  {
    path: "/organization/change-pin",
    component: ChangePinPage
  },
  { path: "/classes", redirect: "/" },
  {
    path: "/classes/create",
    component: ActivityCreatePage,
    onEnter: "requireAuth"
  },
  {
    path: "/classes/:uid/edit",
    component: ActivityEditPage,
    onEnter: "requireAuth"
  },
  {
    path: "/classes/:uid/schedule",
    component: ActivitySchedule,
    onEnter: "requireAuth"
  },
  {
    path: "/classes/:uid/posts",
    component: ActivityPostsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/classes/:uid/availability",
    component: ActivityAvailabilityPage,
    onEnter: "requireAuth"
  },
  {
    path: "/class-links/create",
    component: BundleCreatePage,
    onEnter: "requireAuth"
  },
  {
    path: "/org-teacher/availability",
    component: ActivityAvailabilityPage,
    onEnter: "requireAuth"
  },
  {
    path: "/classes/:uid/approval",
    component: ActivityApproval,
    onEnter: "requireRole",
    roles: [Roles.ClassApproval]
  },
  {
    path: "/classes/:uid/feedback",
    component: ReviewSubmissionPage,
    onEnter: "requireAuth"
  },
  {
    path: "/classes/:uid/syllabus",
    component: SyllabusEditPage,
    onEnter: "requireAuth"
  },
  { path: "/activities", redirect: "/listings" },
  { path: "/activities/create", redirect: "/classes/create" },
  { path: "/activities/proposal", redirect: "/classes/create" },
  { path: "/activities/:activityUid", redirect: "/classes/:activityUid" },
  {
    path: "/activities/:activityUid/enroll",
    redirect: "/classes/:activityUid/enroll"
  },
  {
    path: "/activities/:activityUid/email",
    redirect: "/classes/:activityUid/email"
  },
  { path: "/activities/:uid/edit", redirect: "/classes/:uid/edit" },
  { path: "/activities/:uid/schedule", redirect: "/classes/:uid/schedule" },
  { path: "/activities/:uid/admin", redirect: "/classes/:uid/admin" },
  { path: "/activities/:uid/feedback", redirect: "/classes/:uid/feedback" },
  { path: "/activity/:uid", redirect: "/classes/:uid" },
  { path: "/activity/:uid/:page", redirect: "/classes/:uid/:page" },
  {
    path: "/search",
    name: "search",
    component: SearchPage,
    isTranslated: true
  }, // old page
  // new page
  {
    path: "/online-classes",
    name: "catalog-hub",
    component: OnlineClassesPage,
    isTranslated: true
  },
  {
    path: "/landing/:themeOrQuery/",
    name: "category-paid",
    component: PaidCategoryLandingPage,
    isTranslated: true
  },
  {
    path: "/landing/:path/:themeOrQuery/",
    name: "category-paid",
    component: PaidCategoryLandingPage,
    isTranslated: true
  },
  {
    path: "/online-classes/:path/:themeOrQuery",
    name: "category",
    component: OnlineClassesPage,
    isTranslated: true
  },
  // this rule supports search path params /online-classes/arts
  // redirects from ?q=arts --> /arts are handled by cloudflare
  {
    path: "/online-classes/:themeOrQuery",
    name: "category",
    component: OnlineClassesPage,
    isTranslated: true
  },
  {
    path: "/schools/:themeOrQuery",
    name: "category",
    component: OnlineClassesPage,
    isTranslated: false
  },
  {
    path: "/online-classes/sfbay",
    redirect: "/online-classes",
    isTranslated: true
  },
  {
    path: "/tutoring",
    name: "tutoring",
    component: TutoringPage,
    isTranslated: true
  },
  {
    path: "/cart",
    component: ShoppingCart,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/cart/checkout",
    component: ShoppingCartCheckout,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/onboarding-quiz",
    name: "onboardingQuiz",
    component: OnboardingQuizPage,
    isTranslated: true
  },
  {
    path: "/pitch-page",
    name: "pitchPage",
    component: PitchPage,
    isTranslated: true
  },
  { path: "/categories", redirect: "/online-classes", isTranslated: true },
  { path: "/listings", component: SearchPage, isTranslated: true },
  {
    path: "/dashboard",
    exact: false,
    component: ParentDashboard,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/enrolled/:sectionUid",
    name: "enrolled-class",
    exact: false,
    component: EnrolledClass,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/classroom/:sectionUid",
    component: Classroom,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/classroom/:sectionUid/posts/:classPostUid",
    component: SinglePost,
    onEnter: "requireAuth"
  },
  {
    path: "/user/:uid/confirm-other-emails",
    component: ConfirmOtherEmailsPage,
    isTranslated: true
  },
  {
    path: "/private-class-messages/:sectionUid",
    component: PrivateClassMessagesPage,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/classroom/:sectionUid/recording/:recordingUid",
    component: MuxRecording,
    onEnter: "requireAuth",
    isTranslated: true
  },

  {
    path: "/sections",
    redirect: "/listings"
  },
  {
    path: "/sections/:sectionUid",
    component: SectionToActivityRedirect
  },
  {
    path: "/sections/:uid/edit",
    component: SectionEdit,
    onEnter: "requireAuth"
  },
  {
    path: "/sections/:uid/posts",
    component: SectionPostsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/sections/:uid/manage",
    component: SectionSelfPacedManage,
    onEnter: "requireAuth"
  },
  {
    path: "/sections/:uid/attendees",
    redirect: "/sections/:uid/learners"
  },
  {
    path: "/sections/:uid/learners",
    component: SectionLearners,
    onEnter: "requireAuth"
  },
  {
    path: "/sections/:uid/learners/:learnerOrEnrollmentUid",
    component: SectionLearners,
    onEnter: "requireAuth"
  },
  {
    path: "/sections/:uid/recordings",
    component: SectionRecordings,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/sections/:uid/join",
    component: SectionJoin,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/sections/:uid/meetings",
    component: SectionMeetings,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/sections/:uid/notes",
    component: SectionAiAssistant,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/sections/:sectionUid/lessons",
    component: Classroom,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/sections/:sectionUid/assignments",
    component: SectionAssignments,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/autoplay",
    name: "Autplay testing sandbox",
    component: AutoplaySandbox,
    isTranslated: false,
    exact: false
  },
  {
    path: "/join-membership",
    name: "Join Membership",
    component: JoinMembershipPage,
    isTranslated: true
  },
  {
    path: "/learn",
    redirect: "/classes"
  },
  {
    path: "/learn/schedule",
    component: ParentSchedule,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/learn/tutor-match",
    component: TutorMatch,
    isTranslated: true
  },
  {
    path: "/account/activities",
    redirect: "/learn/schedule"
  },
  {
    path: "/account/enrolled",
    redirect: "/learn/schedule"
  },
  {
    path: "/account",
    redirect: "/classes"
  },
  {
    path: "/account/payments",
    redirect: "/account/transactions"
  },
  {
    path: "/account/transactions",
    component: Transactions,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/enrollment-confirmation/:sectionUid",
    component: PostEnrollConfirmationPage,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/bulk-order-confirmation/:orderSlugId",
    component: PostOrderConfirmationPage,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/account/payments/:orderSlugId",
    component: Receipt,
    onEnter: "requireAuth"
  },
  {
    path: "/account/listings",
    redirect: "/teach"
  },
  {
    path: "/account/schedule",
    redirect: "/teach/schedule"
  },
  {
    path: "/account/classes",
    redirect: "/teach/classes"
  },
  {
    path: "/saved-classes/:savedClassListUid",
    component: SavedClasses
  },
  {
    path: "/account/saved-classes",
    component: SavedClasses,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/account/followed-teachers",
    component: FollowedTeachers,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/account/saved-searches",
    component: SavedSearches,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/account/saved",
    redirect: "/account/saved-classes",
    isTranslated: true
  },
  {
    path: "/account/favorites",
    redirect: "/account/saved-classes",
    isTranslated: true
  },
  {
    path: "/account/leader",
    redirect: "/account/teacher"
  },
  {
    path: "/account/teacher",
    component: LeaderSettings,
    onEnter: "requireAuth"
  },
  {
    path: "/account/parent",
    component: ParentSettings,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/account/email_settings",
    redirect: "/account/email-settings"
  },
  {
    path: "/account/email-settings",
    component: EmailSettings,
    isTranslated: true
  },
  {
    path: "/account/email_settings/unsubscribe",
    redirect: "/account/email-settings/unsubscribe"
  },
  {
    path: "/account/email-settings/unsubscribe",
    redirect: "/account/email-settings"
  },
  {
    path: "/account/settings",
    redirect: "/account/parent"
  },
  {
    path: "/account/transcripts",
    component: Transcripts,
    onEnter: "requireAuth"
  },
  // Educator mode conversations paths
  {
    path: "/teach/conversations/learners/:threadUid",
    redirect: "/teach/conversations/learners/threads/:threadUid"
  },
  {
    path: "/teach/conversations/learners",
    exact: false,
    component: TeacherLearnerConversations,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/teach/conversations/:threadUid",
    redirect: "/teach/conversations/threads/:threadUid"
  },
  {
    path: "/teach/conversations",
    exact: false,
    component: TeacherParentConversations,
    onEnter: "requireAuth",
    isTranslated: true
  },
  // Pre-educator-mode teacher-student conversations path
  {
    path: "/conversations/learners/:threadUid",
    redirect: "/conversations/learners/threads/:threadUid"
  },
  {
    path: "/conversations/learners",
    component: TeacherLearnerConversations,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/conversations/:threadUid",
    redirect: "/conversations/threads/:threadUid"
  },
  {
    path: "/conversations",
    exact: false,
    component: TeacherParentConversations,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/teach/classes",
    component: TeacherClassesPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/class-links",
    component: TeacherClassLinksPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/apply",
    component: TeacherApplication,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/dashboard",
    component: TeacherDashboard,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/broadcast",
    component: TeacherBroadcast,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/schedule",
    component: TeacherSchedule,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/schedule-tips",
    component: TeacherSchedulingTipsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/sections",
    component: TeacherSectionsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/coupons",
    component: TeacherDiscountsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/coupons/create",
    component: TeacherDiscountsCreatePage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/coupons/:uid",
    component: TeacherDiscountsDetailsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/availability",
    component: TeacherAvailabilityPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/stats",
    component: TeacherStats,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/marketing",
    component: TeacherMarketing,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/invite",
    component: TeachInvitesPage,
    onEnter: "requireNonLoggedIn"
  },
  {
    path: "/teach/feedback",
    component: TeacherFeedbackPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/credentials",
    component: TeacherCredentialsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/leaders/:uidOrLink",
    redirect: "/teachers/:uidOrLink"
  },
  {
    path: "/leaders/:uidOrLink/follow",
    redirect: { pathname: "/teachers/:uidOrLink", query: { signup: true } }
  },
  {
    path: "/teachers/:uidOrLink/follow",
    redirect: { pathname: "/teachers/:uidOrLink", query: { signup: true } }
  },
  {
    path: "/teachers/:uidOrLink",
    name: "teacher-profile",
    component: TeacherProfile
  },
  {
    path: "/parents/:uid",
    component: ParentProfile,
    isTranslated: true
  },
  {
    path: "/parents/:uid/:profileLinkUid",
    component: ParentProfile,
    isTranslated: true
  },
  {
    path: "/parent-profile/:uid/:profileLinkUid",
    component: ParentProfile,
    redirect: "/parents/:uid/:profileLinkUid"
  },
  {
    path: "/parent-profile/:uid/",
    component: ParentProfile,
    redirect: "/parents/:uid"
  },
  {
    path: "/parent-profile",
    component: ParentProfile,
    isTranslated: true,
    onEnter: "requireAuth"
  },
  {
    path: "/about",
    component: AboutPage,
    isTranslated: true
  },
  {
    path: "/kansas-keep-verification",
    component: KansasKeepVerificationPage,
    isTranslated: true
  },
  {
    path: "/careers",
    component: CareersPage
  },
  {
    path: "/terms-for-org-teachers",
    component: OrgsTermsPage
  },
  {
    path: "/safety",
    redirect: "/trust"
  },
  {
    path: "/trust",
    component: TrustAndSafetyPage,
    isTranslated: true
  },
  {
    path: "/lead-activities",
    redirect: "/teach"
  },
  {
    path: "/teach",
    component: Teach
  },
  {
    path: "/teach/setup",
    redirect: "/teach"
  },
  {
    path: "/unsubscribed",
    component: UnsubscribedConfirmation,
    isTranslated: true
  },
  {
    path: "/unfollowed",
    component: UnfollowedConfirmation
  },
  {
    path: "/personalized-ad-settings",
    component: PersonalizedAdSettingsPage
  },
  {
    path: "/personalized-ads-settings",
    redirect: "/personalized-ad-settings"
  },
  {
    path: "/unscheduled-request",
    component: UnscheduledRequestConfirmation
  },
  {
    path: "/what-we-do",
    redirect: "/about"
  },
  {
    path: "/our-story",
    redirect: "/about"
  },
  {
    path: "/contact",
    redirect: "/about"
  },
  {
    path: "/parent-landing/:ruid",
    redirect: "/teachers/:ruid/follow"
  },
  {
    path: "/invite",
    component: InvitePage,
    onEnter: "requireAuth",
    isTranslated: true
  },
  {
    path: "/refer-teachers",
    component: ReferTeachersPage,
    onEnter: "requireAuth"
  },
  {
    path: "/accounts/merge-request",
    component: MergeAccountRequest,
    onEnter: "requireAuth"
  },
  {
    path: "/accounts/merge-request/confirm",
    component: MergeAccountConfirmRequest,
    onEnter: "requireAuth"
  },
  {
    path: "/accounts/redirect-after-login",
    component: RedirectAfterLogin,
    onEnter: "requireAuth"
  },
  {
    path: "/gift-invite/:acceptGiftSlugId",
    component: AcceptGiftEnrollmentPage
  },
  {
    path: "/teach/required-professional-learning",
    component: TeacherProfessionalLearningPage,
    onEnter: "requireAuth"
  },

  // Seller Org Pages

  {
    path: "/for-organizations",
    component: SellerOrgLandingPage
  },
  {
    path: "/organization",
    component: SellerOrgManage
  },
  {
    path: "/organization/dashboard",
    component: SellerOrgDashboard
  },
  {
    path: "/organization/apply",
    component: SellerOrgApplication,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/:userUid/agreement",
    component: SellerOrgAgreementDownloadPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/agreement",
    component: SellerOrgAgreementPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/teachers",
    component: OrgTeachersPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/schedule",
    component: SellerOrgSchedule,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/classes",
    component: SellerOrgClassesPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/class-links",
    component: SellerOrgClassLinksPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/sections",
    component: SellerOrgSectionsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/profile",
    component: SellerOrgProfilePage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/stats",
    component: SellerOrgStatsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/feedback",
    component: SellerOrgFeedbackPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/operations",
    component: SellerOrgOperationsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/marketing",
    component: SellerOrgMarketingPage,
    onEnter: "requireAuth"
  },
  {
    path: "/organization/credentials",
    component: SellerOrgCredentialsPage,
    onEnter: "requireAuth"
  },

  // Partner Org Pages

  {
    path: "/schools",
    component: SchoolAdminLandingPage,
    onEnter: "requireAdminForSchool"
  },
  {
    path: "/schools/analytics",
    component: SchoolAdminAnalytics,
    onEnter: "requireAdminForSchool"
  },
  {
    path: "/employers",
    component: EmployerAdminLandingPage,
    onEnter: "requireAdminForCompany"
  },
  {
    path: "/employers/analytics",
    component: EmployerAdminAnalytics,
    onEnter: "requireAdminForCompany"
  },
  {
    path: "/partners",
    component: PartnerAdminLandingPage,
    onEnter: "requireAdminForPartner"
  },
  {
    path: "/partners/analytics",
    component: PartnerAdminAnalytics,
    onEnter: "requireAdminForPartner"
  },

  // Admin Pages

  {
    path: "/classes/:uid/admin",
    redirect: "/admin/classes/:uid"
  },
  {
    path: "/admin/classes/:uid",
    component: ActivityAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/sections/:uid/admin",
    redirect: "/admin/sections/:uid"
  },
  {
    path: "/admin/sections/:uid",
    component: SectionAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/email_preview",
    component: EmailPreviewList,
    onEnter: "requireAdmin"
  },
  {
    path: "/email_preview/:name",
    component: EmailPreviewPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/users/:uid",
    component: UserAdmin,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/users-legacy/:uid",
    component: UserAdmin,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/import/users",
    component: UserImportPage,
    onEnter: "requireExplicitRole",
    roles: [Roles.OrganizationAdmin]
  },
  {
    path: "/admin/users/:uid/onboard-teacher",
    component: OnboardTeachersPage,
    onEnter: "requireRole",
    roles: [Roles.OnboardTeachers]
  },
  {
    path: "/admin/bulk-create/giftcards",
    component: BulkCreateGiftCardsPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/users/:uid/giftcards",
    component: GiftCardsAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/coupons",
    component: CouponAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/coupons/create",
    component: CouponCreateAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/coupons/:uid",
    component: CouponViewAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/review-teachers",
    component: ReviewTeachersPage,
    onEnter: "requireRole",
    roles: [Roles.OnboardTeachers]
  },
  {
    path: "/admin/review-teachers/:uid",
    component: ReviewTeacherPage,
    onEnter: "requireRole",
    roles: [Roles.OnboardTeachers]
  },
  {
    path: "/admin/review-classes",
    component: ReviewClassesPage,
    onEnter: "requireRole",
    roles: [Roles.ClassApproval]
  },
  {
    path: "/admin/pricing-offers",
    component: PricingOffersAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/pricing-offers/create",
    component: PricingOffersAdminCreatePage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/pricing-offers/:uid/edit",
    component: PricingOffersAdminEditPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/admin/pricing-offers/dashboard",
    component: BuyerOrgDashboard,
    onEnter: "requireAuth"
  },
  {
    path: "/admin/learner-verification",
    component: LearnerVerificationAdminPage,
    onEnter: "requireAdmin"
  },
  {
    path: "/redirect-to-learner",
    component: RedirectToLearnerModePage,
    onEnter: "requireAuth"
  },
  /**@deprecated "club" URLs ---> "group" urls */
  {
    path: "/clubs/create",
    redirect: "/groups/create"
  },
  {
    path: "/groups/create",
    component: ActivityCreatePage,
    onEnter: "requireAuth"
  },
  {
    path: "/clubs/:activityUid/edit",
    redirect: "/groups/:activityUid/edit"
  },
  {
    path: "/groups/:activityUid/edit",
    component: ClubEditPage,
    onEnter: "requireAuth"
  },
  {
    path: "/groups/:activityUid/manage",
    component: ClubManageListing,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/clubs",
    redirect: "/teach/groups"
  },
  {
    path: "/teach/groups",
    component: TeacherClubsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/teach/class-links/:bundleUid/edit",
    component: BundleEditPage,
    onEnter: "requireAuth"
  },
  {
    path: "/class-links/:bundleUid",
    component: BundlePage
  },
  {
    path: "/organization/clubs",
    redirect: "/organization/groups"
  },
  {
    path: "/organization/groups",
    component: SellerOrgOwnerClubsPage,
    onEnter: "requireAuth"
  },
  {
    path: "/club/:sectionUid",
    redirect: "/group/:sectionUid"
  },
  {
    path: "/club/:sectionUid/posts/:classPostUid",
    redirect: "/group/:sectionUid/posts/:classPostUid"
  },
  {
    path: "/group/:sectionUid",
    component: ClubHomePage,
    onEnter: "requireAuth"
  },
  {
    path: "/group/:sectionUid/posts/:classPostUid",
    component: ClubPostPage,
    onEnter: "requireAuth"
  },
  {
    path: "/group/:sectionUid/posts/:classPostUid/comments/:classPostCommentUid",
    component: ClubPostPage,
    onEnter: "requireAuth"
  },
  {
    path: "/learner/:learnerUid/profile",
    component: LearnerProfilePage,
    onEnter: "requireAuth"
  },
  {
    path: "/admin/organizations",
    component: OrganizationsAdminPage,
    onEnter: "requireRole",
    roles: [Roles.OrganizationAdmin]
  },
  {
    path: "/admin/organizations/create",
    component: OrganizationsAdminCreatePage,
    onEnter: "requireRole",
    roles: [Roles.OrganizationAdmin]
  },
  {
    path: "/admin/organizations/:uid/edit",
    component: OrganizationsAdminEditPage,
    onEnter: "requireRole",
    roles: [Roles.OrganizationAdmin]
  },
  {
    path: "/learner-login-switcher",
    component: LearnerLoginSwitcher,
    isTranslated: true
  },
  {
    path: "/groups",
    component: ClubsLandingPage
  },
  // Needs to be at bottom because it's not exact
  {
    path: "/classes/:activitySlugOrUid",
    name: "class",
    component: ActivityDetailPage,
    exact: false,
    isTranslated: true
  },
  {
    path: "/groups/:activitySlugOrUid",
    name: "group",
    component: ActivityDetailPage,
    exact: false,
    isTranslated: true
  },
  {
    path: "/BRETT",
    redirect:
      "/online-classes/homeschool?couponCode=BRETT&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-BrettCooper",
    isTranslated: true
  },
  {
    path: "/NOMADIC",
    redirect:
      "/online-classes/popular?couponCode=NOMADIC20&utm_medium=paid-influencer&utm_source=youtube&utm_campaign=paid-influencer-NomadicMovement",
    isTranslated: true
  },
  {
    path: "/AE",
    redirect:
      "/online-classes/popular?couponCode=AE&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-AnnieElise",
    isTranslated: true
  },
  {
    path: "/UNPLANNED",
    redirect:
      "online-classes/popular?couponCode=UNPLANNED&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-UnplannedPodcast",
    isTranslated: true
  },
  {
    path: "/PAKMAN",
    redirect:
      "online-classes/popular?couponCode=PAKMAN&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-Pakman",
    isTranslated: true
  },
  {
    path: "/HUMANS",
    redirect:
      "online-classes/humans?couponCode=HUMANS&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-RaisingGoodHumans",
    isTranslated: true
  },
  {
    path: "/LEGENDS",
    redirect:
      "online-classes/legends?couponCode=LEGENDS&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-MythandLegends",
    isTranslated: true
  },
  {
    path: "/CREEPY",
    redirect:
      "online-classes/creepy?couponCode=CREEPY&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-Sinisterhood",
    isTranslated: true
  },
  {
    path: "/STOLEN",
    redirect:
      "online-classes/stolen?couponCode=STOLEN&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-StolenLivesTrueCrime",
    isTranslated: true
  },
  {
    path: "/GHOST",
    redirect:
      "online-classes/ghost?couponCode=GHOST&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-RealGhostStoriesOnline",
    isTranslated: true
  },
  {
    path: "/MOMDAD",
    redirect:
      "online-classes/momdad?couponCode=MOMDAD&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-YourMomandDad",
    isTranslated: true
  },
  {
    path: "/TUTORHELP",
    redirect:
      "tutoring?couponCode=TUTORING20&utm_medium=paid-newsletter&utm_source=newsletter&utm_campaign=paid-newsletter-PeachJar",
    isTranslated: true
  },
  {
    path: "/CAMPS",
    redirect:
      "online-classes/spring-camps?couponCode=CAMPS20&utm_medium=paid-newsletter&utm_source=newsletter&utm_campaign=paid-newsletter-PeachJar",
    isTranslated: true
  },
  {
    path: "/SUMMERCAMP",
    redirect:
      "online-classes/summer-camps?couponCode=CAMPS20&utm_medium=paid-newsletter&utm_source=newsletter&utm_campaign=paid-newsletter-PeachJar",
    isTranslated: true
  },
  {
    path: "/WARROOM",
    redirect:
      "online-classes/popular?couponCode=WARROOM&utm_medium=paid-podcast&utm_source=podcast&utm_campaign=paid-podcast-PoliticsWarroom",
    isTranslated: true
  },
  {
    path: "/PAIGEKOSER",
    redirect:
      "online-classes/homeschool?couponCode=KOSER20&utm_medium=paid-influencer&utm_source=instagram&utm_campaign=paid-influencer-PaigeKoser",
    isTranslated: true
  },
  {
    path: "/KAMRY",
    redirect:
      "online-classes/popular?couponCode=KAMRY20&utm_medium=paid-influencer&utm_source=instagram&utm_campaign=paid-influencer-KamryVincent",
    isTranslated: true
  },
  {
    path: "/LITTLEFENDERS",
    redirect:
      "join-membership?couponCode=START20&utm_medium=paid-influencer&utm_source=instagram&utm_campaign=paid-influencer-LittleFenders",
    isTranslated: true
  },
  {
    path: "/TWINS",
    redirect:
      "join-membership?couponCode=START20&utm_medium=paid-influencer&utm_source=instagram&utm_campaign=paid-influencer-TwinsbytheBeach",
    isTranslated: true
  },
  {
    path: "/EMMASEA",
    redirect:
      "join-membership?couponCode=START20&utm_medium=paid-influencer&utm_source=tiktok&utm_campaign=paid-influencer-emmaseabourn",
    isTranslated: true
  },
  {
    path: "/CHELSEA",
    redirect:
      "landing-page/courses?couponCode=CHELSEA&utm_medium=paid-influencer&utm_source=instagram&utm_campaign=paid-influencer-ChelseaDyanTuran",
    isTranslated: true
  }
];

export default ROUTES;
