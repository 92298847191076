import { gql } from "@outschool/ui-apollo";

export const EmailPreferenceFragment = gql`
  fragment EmailPreferenceFragment on EmailPreference {
    id
    name
    label
    description
    isEnabled
  }
`;

export const EmailPreferenceGroupFragment = gql`
  fragment EmailPreferenceGroupFragment on EmailPreference {
    ...EmailPreferenceFragment
    childPreferences {
      ...EmailPreferenceFragment
      childPreferences {
        ...EmailPreferenceFragment
      }
    }
  }
  ${EmailPreferenceFragment}
`;
