import { useTranslation } from "@outschool/localization";
import React from "react";

import { Message } from "./components/Message";
import { Sender } from "./consts";

export const Welcome = () => {
  const { t } = useTranslation("client\\components\\chat");

  return (
    <Message
      text={t("Welcome to Outschool Guide how can we help?")}
      sender={Sender.System}
    />
  );
};
