import {
  CreateAiGuideSupportIssueMutation,
  CreateAiGuideSupportIssueMutationVariables
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useMutation } from "@outschool/ui-apollo";
import React, { useState } from "react";

import { useConciergeActiveEnrollmentQuery } from "../../../hooks/useConciergeActiveEnrollmentQuery";
import { createAiGuideSupportIssueMutation } from "../../../queries/AiGuideSupportIssueQueries";
import { useChatContext } from "../chatProvider";
import { ChatInputField } from "../components/ChatInputField";
import { ChatInputSelection } from "../components/ChatInputSelection";

type ActivityOption = {
  uid: string;
  title: string;
};

export const HelpMeWithCurrentClassInput = () => {
  const { showErrorMessage, sendAiGuideSupportIssueSubmit } = useChatContext();
  const [inputValue, setInputValue] = useState("");
  const [selectedActivity, setSelectedActivity] = useState<
    ActivityOption | undefined
  >(undefined);

  const { t } = useTranslation("client\\components\\chat\\conciergeChat");
  const trackEvent = useTrackEvent();

  const { data } = useConciergeActiveEnrollmentQuery({});
  const enrolledActivities: ActivityOption[] = data?.currentUser
    ?.activeEnrollments
    ? data?.currentUser?.activeEnrollments
        .filter(
          enrollment => enrollment?.section && enrollment.section.activity
        )
        .map(enrollment => ({
          uid: enrollment!.section!.activity.uid,
          title: enrollment!.section!.activity.title
        }))
    : [];

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const [createAiGuideSupportIssue] = useMutation<
    CreateAiGuideSupportIssueMutation,
    CreateAiGuideSupportIssueMutationVariables
  >(createAiGuideSupportIssueMutation);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackEvent("ai_guide_help_me_with_current_class_submit_button_touch");

    const activity = selectedActivity
      ? selectedActivity
      : enrolledActivities[0];
    const userMessage = activity
      ? `${inputValue || "Your message"} (Class: ${activity.title})`
      : `${inputValue || "Your message"}`;

    try {
      await createAiGuideSupportIssue({ variables: { userMessage } });
      sendAiGuideSupportIssueSubmit(userMessage);
    } catch (error) {
      OsPlatform.captureError(error);
      showErrorMessage(
        userMessage,
        t`Sorry! We are not able to send your request at this time.`
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ChatInputField
        inputHelperText={t("Describe how I can help with this class...")}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      >
        {!!enrolledActivities?.length && (
          <ChatInputSelection
            label={t`Select Class`}
            options={enrolledActivities}
            optionLabel={`title`}
            onSelect={activity => setSelectedActivity(activity)}
          />
        )}
      </ChatInputField>
    </form>
  );
};
