import { ClassInquiryType, StateType } from "./chatProvider";
import { SelectedLearner } from "./components/LearnerSelection";
import { Message, MessageIdType } from "./consts";

export const ACTION_TYPES = {
  GO_TO_NEXT_MESSAGE: "GO_TO_NEXT_MESSAGE",
  SET_LOADING: "SET_LOADING",
  CLASS_INQUIRY_CREATED: "CLASS_INQUIRY_CREATED",
  UPDATE_LEARNER: "UPDATE_LEANER"
} as const;

export type UpdateLearnerAction = {
  type: typeof ACTION_TYPES.UPDATE_LEARNER;
  payload: {
    learner: SelectedLearner;
  };
};
export type GoToNextMessageAction = {
  type: typeof ACTION_TYPES.GO_TO_NEXT_MESSAGE;
  payload: {
    nextMessageId: MessageIdType;
    messages: Message[]; // The messages order matters
  };
};

export type ClassInquiryCreatedAction = {
  type: typeof ACTION_TYPES.CLASS_INQUIRY_CREATED;
  payload: {
    classInquiry: ClassInquiryType;
    nextMessageId: MessageIdType;
    messages: Message[]; // The messages order matters
  };
};

export type ChatActionType =
  | GoToNextMessageAction
  | ClassInquiryCreatedAction
  | UpdateLearnerAction;

export const chatReducer = (
  state: StateType,
  action: ChatActionType
): StateType => {
  switch (action.type) {
    case ACTION_TYPES.GO_TO_NEXT_MESSAGE: {
      const { payload } = action;
      const { nextMessageId, messages } = payload;

      return {
        ...state,
        currentMessageId: nextMessageId,
        conversation: [...state.conversation, ...messages]
      };
    }
    case ACTION_TYPES.CLASS_INQUIRY_CREATED: {
      const { payload } = action;
      const { classInquiry, nextMessageId, messages } = payload;
      return {
        ...state,
        currentMessageId: nextMessageId,
        classInquiry,
        conversation: [...state.conversation, ...messages]
      };
    }
    case ACTION_TYPES.UPDATE_LEARNER: {
      const { payload } = action;
      return {
        ...state,
        selectedLearner: payload.learner
      };
    }
    default:
      return state;
  }
};
