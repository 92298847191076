import {
  MutationResendEmailConfirmationArgs,
  MutationResendOtherEmailConfirmationArgs
} from "@outschool/gql-backend-generated";
import {
  CreateSetupIntentMutation,
  CreateSetupIntentMutationVariables,
  CurrentUserHasEnrolledQuery,
  CurrentUserHasEnrolledQueryVariables,
  CurrentUserPaymentMethodsQuery,
  CurrentUserPaymentMethodsQueryVariables,
  CurrentUserPricingOfferAdminQuery,
  CurrentUserPricingOfferAdminQueryVariables,
  CurrentUserPricingOffersQuery,
  CurrentUserPricingOffersQueryVariables,
  CurrentUserQueryQuery,
  ResendEmailConfirmationMutation,
  ResendOtherEmailConfirmationMutation
} from "@outschool/gql-frontend-generated";
import { createDayjs } from "@outschool/time";
import { useAnalytics } from "@outschool/ui-analytics";
import {
  gql,
  useMutation,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";

import {
  resendEmailConfirmationMutation,
  resendOtherEmailConfirmation
} from "../components/emailWarningQueries";

export const CurrentUserHasEnrolled = gql`
  query CurrentUserHasEnrolled {
    currentUser {
      uid
      hasEnrolled
    }
  }
`;

type UseHasEnrolledArgs = {
  skip?: boolean;
  cache?: boolean;
};

export function useHasEnrolled(props?: UseHasEnrolledArgs) {
  const { isLoggedIn } = useSession();
  const { data, loading } = useQueryWithPreviousData<
    CurrentUserHasEnrolledQuery,
    CurrentUserHasEnrolledQueryVariables
  >(CurrentUserHasEnrolled, {
    skip: !isLoggedIn || props?.skip,
    fetchPolicy: props?.cache ? "cache-first" : "cache-and-network"
  });
  const hasEnrolledInClasses =
    data?.currentUser?.hasEnrolled ?? props?.skip ?? false;
  const loggedInAndHasEnrolled = React.useMemo(
    () => isLoggedIn && hasEnrolledInClasses,
    [isLoggedIn, hasEnrolledInClasses]
  );
  return { hasEnrolled: loggedInAndHasEnrolled, loading };
}

export const CurrentUserPricingOfferAdmin = gql`
  query CurrentUserPricingOfferAdmin {
    currentUser {
      uid
      pricingOfferAdmin {
        organization {
          uid
        }
      }
    }
  }
`;

type UsePricingOfferAdminArgs = {
  cache?: boolean;
};

export function usePricingOfferAdmin(
  props?: UsePricingOfferAdminArgs
): string | undefined {
  const { isLoggedIn } = useSession();
  const { data } = useQueryWithPreviousData<
    CurrentUserPricingOfferAdminQuery,
    CurrentUserPricingOfferAdminQueryVariables
  >(CurrentUserPricingOfferAdmin, {
    skip: !isLoggedIn,
    fetchPolicy: props?.cache ? "cache-first" : "cache-and-network"
  });
  const pricingOfferAdminOrgUid =
    data?.currentUser?.pricingOfferAdmin?.organization?.uid;
  return isLoggedIn ? pricingOfferAdminOrgUid : undefined;
}

const CurrentUserPaymentMethods = gql`
  query CurrentUserPaymentMethods {
    currentUser {
      uid
      paymentMethods {
        uid
        type
        brand
        last4
        expirationMonth
        expirationYear
      }
    }
  }
`;

export function usePaymentMethods() {
  const { isLoggedIn } = useSession();
  const { error, loading, data, refetch } = useQueryWithPreviousData<
    CurrentUserPaymentMethodsQuery,
    CurrentUserPaymentMethodsQueryVariables
  >(CurrentUserPaymentMethods, {
    skip: !isLoggedIn,
    // TODO(jess): we can remove this once settleOrder flushes cached sources
    fetchPolicy: "network-only"
  });
  return {
    refetch,
    error,
    loading,
    paymentMethods: data?.currentUser?.paymentMethods ?? []
  };
}

const DeletePaymentMethod = gql`
  mutation DeletePaymentMethod($paymentMethodId: ID!) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId)
  }
`;

export function useDeletePaymentMethod(): [
  (paymentMethodId: string) => Promise<void>,
  { error?: Error; loading: boolean }
] {
  const [mutate, { error, loading }] = useMutation(DeletePaymentMethod, {});
  const wrappedMutation = React.useCallback(
    async (paymentMethodId: string) => {
      await mutate({
        variables: { paymentMethodId },
        update: cache => {
          const data = cache.readQuery<
            CurrentUserPaymentMethodsQuery,
            CurrentUserPaymentMethodsQueryVariables
          >({ query: CurrentUserPaymentMethods });
          const paymentMethods = data?.currentUser?.paymentMethods.filter(
            paymentMethod => paymentMethod.uid !== paymentMethodId
          );
          cache.writeQuery({
            query: CurrentUserPaymentMethods,
            data: {
              ...data,
              currentUser: {
                ...(data?.currentUser ?? {}),
                paymentMethods
              }
            }
          });
        }
      });
    },
    [mutate]
  );
  return [wrappedMutation, { error, loading }];
}

const CreateSetupIntent = gql`
  mutation CreateSetupIntent {
    createSetupIntent {
      setupIntentClientId
    }
  }
`;

export function useCreateSetupIntentWithPaymentElement(
  paymentMethodUid?: string
): [() => Promise<SetupIntent>, { error?: Error; loading: boolean }] {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | undefined>();
  const stripe = useStripe();
  const elements = useElements();

  const [createSetupIntentMutation] = useMutation<
    CreateSetupIntentMutation,
    CreateSetupIntentMutationVariables
  >(CreateSetupIntent, {});
  const wrappedMutation = React.useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await createSetupIntentMutation();
      if (!data?.createSetupIntent?.setupIntentClientId) {
        throw new Error(
          "An unknown error occurred while setting up your card.  Please try again later."
        );
      }
      if (!stripe || !elements) {
        throw new Error(
          "Stripe is not initiatized yet.  Please try again in a few seconds."
        );
      }
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      const { error, setupIntent } = paymentMethodUid
        ? await stripe.confirmCardSetup(
            data.createSetupIntent.setupIntentClientId,
            {
              payment_method: paymentMethodUid
            }
          )
        : await stripe.confirmSetup({
            elements,
            clientSecret: data.createSetupIntent.setupIntentClientId,
            redirect: "if_required"
          });
      if (error) {
        throw error;
      }
      return setupIntent;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [paymentMethodUid, createSetupIntentMutation, stripe, elements]);
  return [wrappedMutation, { error, loading }];
}

export function useResendEmailConfirmation(
  currentUser: CurrentUserQueryQuery["currentUser"],
  emailToConfirm?: string
) {
  const isNonPrimaryEmail =
    emailToConfirm && currentUser?.email !== emailToConfirm;
  const [resendEmail] = useMutation<
    ResendEmailConfirmationMutation,
    MutationResendEmailConfirmationArgs
  >(resendEmailConfirmationMutation);
  const [resendOtherEmail] = useMutation<
    ResendOtherEmailConfirmationMutation,
    MutationResendOtherEmailConfirmationArgs
  >(resendOtherEmailConfirmation);

  const sendEmail = isNonPrimaryEmail
    ? async () => {
        if (!currentUser?.uid) {
          throw new Error("Current user is not loaded yet");
        }
        const { data } = await resendOtherEmail({
          variables: {
            userUid: currentUser.uid,
            otherEmail: emailToConfirm
          }
        });
        return data?.resendOtherEmailConfirmation;
      }
    : async () => {
        if (!currentUser?.uid) {
          throw new Error("Current user is not loaded yet");
        }
        const { data } = await resendEmail({
          variables: { userUid: currentUser?.uid }
        });
        return data?.resendEmailConfirmation;
      };
  return sendEmail;
}

type CurrentUserAttribution = {
  anonymousId?: string | null;
  wasReferred?: boolean;
};

export function useAttribution() {
  const [attribution, setAttribution] = useState<CurrentUserAttribution>({});
  const { currentUser, isLoggedIn } = useSession();
  const analytics = useAnalytics();

  useEffect(() => {
    async function updateAttribution() {
      if (isLoggedIn && currentUser) {
        setAttribution({
          anonymousId: currentUser.anonymousId,
          wasReferred: currentUser.wasReferred
        });
      }

      if (!isLoggedIn) {
        const attribution = await analytics.attribution();
        setAttribution({
          anonymousId: await analytics.anonymousId(),
          wasReferred:
            !!attribution?.usid || !!attribution?.addressBarUserSlugId
        });
      }
    }

    updateAttribution();
  }, [analytics, currentUser, isLoggedIn]);
  return attribution;
}

export function useCanRequestListing({
  hasPublishedAClass = false
}: {
  hasPublishedAClass?: boolean;
}) {
  const { currentUser } = useSession();

  const teacherBioComplete =
    currentUser && currentUser.details && Boolean(currentUser.details.about);
  const requiredTrainingCompleteForTeachers =
    currentUser &&
    currentUser.hasCompletedClassContentPoliciesTraining &&
    currentUser.hasCompletedSafetyAndPrivacyTraining;
  const requiredTrainingCompleteForOrgAdmin =
    currentUser &&
    currentUser.hasCompletedClassContentPoliciesOrgAdminTraining &&
    currentUser.hasCompletedSafetyAndPrivacyOrgAdminTraining;
  const isApprovedTeacher =
    currentUser && Boolean(currentUser.teacher_approved_at);
  const isSellerOrg = currentUser?.sellerOrg?.currentUserIsOwner;
  const canRequestListing =
    (isSellerOrg && requiredTrainingCompleteForOrgAdmin) ||
    (teacherBioComplete &&
      requiredTrainingCompleteForTeachers &&
      isApprovedTeacher) ||
    hasPublishedAClass;
  return canRequestListing;
}

export const CurrentUserPricingOffers = gql`
  query CurrentUserPricingOffers {
    currentUser {
      uid
      pricingOffers {
        uid
        name
        startAfter
        endBy
        buyerOrgName
        organizationUid
        remainingCapCents
        discount
        isFinancialAid
        deletedAt
      }
    }
  }
`;

type UsePricingOffersArgs = {
  skip?: boolean;
  onlyWithRemainingCap?: boolean;
};

export function usePricingOffers(props?: UsePricingOffersArgs) {
  const { isLoggedIn } = useSession();
  const { data } = useQueryWithPreviousData<
    CurrentUserPricingOffersQuery,
    CurrentUserPricingOffersQueryVariables
  >(CurrentUserPricingOffers, {
    skip: !isLoggedIn || props?.skip
  });

  const currentTime = createDayjs();
  const pricingOffers = data?.currentUser?.pricingOffers ?? [];
  const filteredPricingOffers = pricingOffers.filter(
    pricingOffer =>
      currentTime.isAfter(createDayjs(pricingOffer.startAfter)) &&
      currentTime.isBefore(createDayjs(pricingOffer.endBy)) &&
      (!props?.onlyWithRemainingCap || !!pricingOffer.remainingCapCents)
  );

  return React.useMemo(
    () => (isLoggedIn ? filteredPricingOffers : undefined),
    [isLoggedIn, filteredPricingOffers]
  );
}
