import { useLocalStorageState } from "@outschool/local-storage";
import { gql, useQuery } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useEffect, useState } from "react";

import { UserMode, useUserMode } from "../lib/UserMode";

export function useAiSummaryPolling() {
  const { isLoggedIn } = useSession();
  const userMode = useUserMode();
  const isEducatorMode = userMode === UserMode.Educator;

  const { data } = useQuery(
    gql`
      query GetUnsentMeetingSummariesSections {
        unsentMeetingSummariesSections
      }
    `,
    {
      pollInterval: 2 * 60 * 1000, // Poll every 2 minutes
      fetchPolicy: "network-only",
      skip: !isLoggedIn || !isEducatorMode
    }
  );

  const [dismissedSections, setDismissedSections] = useLocalStorageState<
    Record<string, number>
  >("dismissedSections", {});

  const [visibleSection, setVisibleSection] = useState(null);

  useEffect(() => {
    const now = Date.now();

    // Remove entries after 2 hours
    const dismissedUnexpiredSections = Object.fromEntries(
      Object.entries(dismissedSections).filter(
        ([_sectionUid, timestamp]) => now - timestamp < 2 * 60 * 60 * 1000
      )
    );

    // Only update state if there are changes (prevents infinite loop)
    if (
      JSON.stringify(dismissedUnexpiredSections) !==
      JSON.stringify(dismissedSections)
    ) {
      setDismissedSections(dismissedUnexpiredSections);
    }

    // Find the first non-dismissed section
    const dismissedSectionUids = Object.keys(dismissedUnexpiredSections);
    const newSection = data?.unsentMeetingSummariesSections.find(
      (sectionUid: string) => !dismissedSectionUids.includes(sectionUid)
    );

    setVisibleSection(newSection || null);
  }, [data, dismissedSections, setDismissedSections]);

  const handleDismiss = () => {
    if (visibleSection) {
      setDismissedSections(prev => ({
        ...prev,
        [visibleSection]: Date.now()
      }));
      setVisibleSection(null);
    }
  };

  return { visibleSection, handleDismiss };
}
