import { SUBSCRIPTION_PLANS } from "@outschool/business-rules";
import {
  SubscriptionCreditBalanceQuery,
  SubscriptionCreditBalanceQueryVariables
} from "@outschool/gql-frontend-generated";
import { dayjs } from "@outschool/time";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import React from "react";

import { useTimeZone } from "../providers/TimeZoneProvider";

export const subscriptionCreditBalanceQuery = gql`
  query SubscriptionCreditBalance {
    subscriptionCreditBalance {
      ...SubscriptionCreditBalanceFragment
    }
  }

  fragment SubscriptionCreditBalanceFragment on SubscriptionCreditBalance {
    uid
    status
    subscriptionId
    checkoutId
    balance
    openToBuy
    packageName
    numCredits
    creditValue
    availableCredits
    billingCycleStart
    billingCycleEnd
    cancelAtPeriodEnd
    amountCents
    upcomingPackage {
      packageName
      numCredits
      creditValue
      amountCents
    }
  }
`;

export const useSubscriptionCreditBalance = () => {
  const { isLoggedIn } = useSession();
  const { timeZone } = useTimeZone();

  const { data, loading, refetch } = useQueryWithPreviousData<
    SubscriptionCreditBalanceQuery,
    SubscriptionCreditBalanceQueryVariables
  >(subscriptionCreditBalanceQuery, {
    skip: !isLoggedIn
  });

  const {
    planSavingsPercentage,
    status,
    availableCredits,
    subscriptionCreditLimit,
    nextSubscriptionStartDate,
    cancelAtPeriodEnd,
    amountCents,
    upcomingPackageCredits,
    upcomingPackageCost
  } = React.useMemo(() => {
    const creditBalance = data?.subscriptionCreditBalance ?? null;
    const upcomingPackage = creditBalance?.upcomingPackage;
    const subscriptionCreditLimit = creditBalance?.numCredits ?? 0;
    const planSavingsPercentage = SUBSCRIPTION_PLANS.find(
      plan => plan.numCredits === creditBalance?.numCredits
    )?.savingsPercentage;

    return {
      planSavingsPercentage,
      status: creditBalance?.status,
      availableCredits: creditBalance?.availableCredits ?? 0,
      subscriptionCreditLimit,
      nextSubscriptionStartDate: !!creditBalance?.billingCycleEnd
        ? dayjs.tz(creditBalance?.billingCycleEnd, timeZone).toDate()
        : null,
      cancelAtPeriodEnd: creditBalance?.cancelAtPeriodEnd,
      amountCents: creditBalance?.amountCents ?? 0,
      upcomingPackageCredits: upcomingPackage?.numCredits ?? null,
      upcomingPackageCost: upcomingPackage?.amountCents ?? null
    };
  }, [data, timeZone]);

  return {
    planSavingsPercentage,
    status,
    availableCredits,
    subscriptionCreditLimit,
    rolloverSubscriptionCreditLimit:
      upcomingPackageCredits ?? subscriptionCreditLimit,
    nextSubscriptionStartDate,
    cancelAtPeriodEnd,
    amountCents,
    upcomingPackageCredits,
    upcomingPackageCost,
    loading,
    refetch
  };
};
