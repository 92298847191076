import { useSession } from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";

const EXPERIMENT_NAME = "AiGuide";

export const useAiGuideExperiment = (experimentName = EXPERIMENT_NAME) => {
  const { currentUserHasLoaded, isLoggedIn } = useSession();

  const { variant, trigger } = useExperiment({
    currentUserHasLoaded,
    experimentName
  });

  const triggerExperiment = () => {
    if (isLoggedIn) {
      trigger();
    }
  };

  const isTreatment = variant === "treatment" && isLoggedIn;

  return {
    isTreatment,
    triggerExperiment
  };
};
