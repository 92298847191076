import { Box, IconButton, TextareaAutosize, Theme } from "@outschool/backpack";
import { faArrowRight } from "@outschool/icons";
import { useIsMobile } from "@outschool/ui-utils";
import React from "react";

const MAX_NUMBER_OF_CHARACTERS_IN_TEXT_FIELD = 1200;

type ChatInputFieldProp = {
  inputHelperText: string;
  inputValue: string;
  onInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
};
export const ChatInputField = ({
  inputHelperText,
  inputValue,
  onInputChange,
  handleSubmit,
  children
}: ChatInputFieldProp) => {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
        gap: "1.6rem",
        borderRadius: "24px",
        padding: "1.6rem",
        boxShadow:
          "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 16px 56px 0px rgba(0, 0, 0, 0.16)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          flexGrow: 1,
          gap: "1rem"
        }}
      >
        {children}
        <TextareaAutosize
          multiline
          fullWidth
          minRows={2}
          maxRows={isMobile ? undefined : 3}
          value={inputValue}
          onChange={onInputChange}
          onKeyPress={(e: React.KeyboardEvent<HTMLFormElement>) => {
            if (e.code === "Enter") {
              handleSubmit(e);
            }
          }}
          maxLength={MAX_NUMBER_OF_CHARACTERS_IN_TEXT_FIELD}
          placeholder={inputHelperText}
          sx={{
            width: "100%",
            lineHeight: 1.5,
            border: "none",
            resize: "none",
            padding: 0,
            "&:focus": {
              outline: "none"
            }
          }}
        />
      </Box>
      <IconButton
        disabled={!inputValue}
        type="submit"
        icon={faArrowRight}
        iconProps={{ size: "medium" }}
        sx={(theme: Theme) => ({
          padding: "15px 15px",
          border: "none",
          outline: "none",
          position: "relative",
          borderRadius: "24px",
          background: `linear-gradient(to right, ${theme.palette.primary[700]}, ${theme.palette.secondary[500]})`,
          cursor: "pointer",
          zIndex: "1",
          boxShadow: `0px 0px 2px 0px ${theme.palette.primary[600]}`,
          "&:hover": {
            cursor: "pointer",
            border: "none"
          },
          "&::before": {
            content: '""',
            position: "absolute",
            top: "2px",
            right: "2px",
            bottom: "2px",
            left: "2px",
            backgroundColor: "common.white",
            borderRadius: "24px",
            zIndex: "-1",
            cursor: "pointer"
          },
          "&.Mui-disabled": { opacity: "0.3" }
        })}
      />
    </Box>
  );
};
