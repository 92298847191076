import { Box, Button, Icon, Theme, Typography } from "@outschool/backpack";
import { faSparkles } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import React, { useState } from "react";

import ConciergeChatModal from "../chat/ConciergeChatModal";

export function ConciergeNavItem() {
  const { t } = useTranslation("client\\components\\nav\\ConciergeNavItem");
  const trackEvent = useTrackEvent();

  const [conciergeChatModalOpen, setConciergeChatModalOpen] =
    useState<boolean>(false);
  const openModal = () => {
    setConciergeChatModalOpen(true);
    trackEvent("ai_guide_modal_opened");
  };

  return (
    <>
      {
        <Box
          onClick={openModal}
          sx={(theme: Theme) => ({
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              marginY: theme.spacing(10)
            }
          })}
        >
          <Button
            sx={(theme: Theme) => ({
              padding: "15px 15px",
              border: "none",
              outline: "none",
              position: "relative",
              borderRadius: "30px",
              background: `linear-gradient(to right, #D401B8, #4B01D4)`,
              cursor: "pointer",
              zIndex: "1",
              "&:hover": {
                cursor: "pointer",
                border: "none"
              },
              "&::before": {
                content: '""',
                position: "absolute",
                top: "3px",
                right: "3px",
                bottom: "3px",
                left: "3px",
                backgroundColor: "white",
                borderRadius: "30px",
                zIndex: "-1",
                cursor: "pointer",
                [theme.breakpoints.down("md")]: {
                  borderRadius: "50%"
                }
              },
              [theme.breakpoints.down("md")]: {
                display: "flex",
                flexDirection: "column-reverse",
                paddingY: theme.spacing(14),
                paddingX: theme.spacing(20),
                borderRadius: "50%"
              }
            })}
          >
            <Typography
              variant="body2"
              sx={(theme: Theme) => ({
                fontSize: "1.6rem",
                lineHeight: "1.8rem",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1rem"
                }
              })}
            >
              {t("Guide")}
            </Typography>
            <Icon
              icon={faSparkles}
              sx={{ fontSize: "2rem", marginLeft: "5px" }}
            />
          </Button>
        </Box>
      }
      <ConciergeChatModal
        isOpen={conciergeChatModalOpen}
        setIsModalOpen={setConciergeChatModalOpen}
      />
    </>
  );
}
