import { useTranslation } from "@outschool/localization";
import { useSession } from "@outschool/ui-auth";
import React from "react";

import { Message } from "../components/Message";
import { Sender } from "../consts";

export const SendClassRequestMessage = () => {
  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");
  const { currentUser } = useSession();

  const learners = currentUser?.children;
  const hasLearners = !!learners?.length;

  return (
    <Message
      text={
        hasLearners
          ? t(
              "Select a learner and share what class you're looking for. Add all relevant details to be matched to the best teacher."
            )
          : t(
              "What class are you looking for? We'll share your request with top teachers who will message you back."
            )
      }
      sender={Sender.System}
    />
  );
};
