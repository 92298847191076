import {
  ConciergeActiveEnrollmentQueryQuery,
  ConciergeActiveEnrollmentQueryQueryVariables
} from "@outschool/gql-frontend-generated";
import { calculateDateBounds } from "@outschool/time";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import React from "react";

import { useTimeZone } from "../providers/TimeZoneProvider";

const conciergeActiveEnrollmentQuery = gql`
  query ConciergeActiveEnrollmentQuery($start: DateTime!, $end: DateTime!) {
    currentUser {
      uid
      activeEnrollments(start: $start, end: $end) {
        uid
        section {
          uid
          activity {
            uid
            title
          }
        }
      }
    }
  }
`;

export function useConciergeActiveEnrollmentQuery({
  skip
}: {
  skip?: boolean;
}) {
  const { timeZone } = useTimeZone();
  // "memoize" today's date so that on re-render the query doesn't erroneously refetch
  const todayRef = React.useRef(new Date());
  const { start, end } = calculateDateBounds(todayRef.current, null, timeZone);
  const [error, setError] = React.useState<Error | null>(null);

  const { loading, data } = useQueryWithPreviousData<
    ConciergeActiveEnrollmentQueryQuery,
    ConciergeActiveEnrollmentQueryQueryVariables
  >(conciergeActiveEnrollmentQuery, {
    variables: { start, end },
    onError: err => {
      console.log("Error trying to fetch active enrollments:", err);
      OsPlatform.captureError(err);
      setError(err);
    },
    skip
  });

  return { data, loading, error };
}
