import {
  Box,
  Button,
  Icon,
  Menu,
  Theme,
  Typography
} from "@outschool/backpack";
import { faChevronDown, faChevronUp } from "@outschool/icons";
import React, { ReactNode } from "react";

export const SelectionMenu = ({
  buttonText,
  children
}: {
  buttonText: string;
  children: ReactNode;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ maxHeight: "3.4rem" }}>
      <Button
        size={"small"}
        onClick={handleClick}
        sx={{
          maxHeight: "3.4rem"
        }}
      >
        <Typography
          sx={(theme: Theme) => ({
            maxWidth: "40rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [theme.breakpoints.down("sm")]: {
              maxWidth: "20rem"
            }
          })}
        >
          {" "}
          {buttonText}
        </Typography>
        <Icon
          icon={open ? faChevronUp : faChevronDown}
          sx={{ marginLeft: "0.4rem" }}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        open={open}
        sx={{
          maxHeight: "30rem",
          borderRadius: "8px",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 16px 32px 0px rgba(0, 0, 0, 0.10)",
            "& ul": {
              padding: 0,
              "& li:hover": { borderRadius: "0 !important" }
            }
          }
        }}
      >
        {children}
      </Menu>
    </Box>
  );
};
