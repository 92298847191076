import { useTranslation } from "@outschool/localization";
import React from "react";

import { Message } from "../components/Message";
import { Sender } from "../consts";

export const HelpMeWithCurrentClassMessage = () => {
  const { t } = useTranslation("client\\components\\chat\\conciergeChat");

  return (
    <Message
      text={t("Sure thing! Select your class and describe how I can help.")}
      sender={Sender.System}
    />
  );
};
