import { Box, Button, Theme, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useIsMobile, useNavigation } from "@outschool/ui-utils";
import React, { lazy, useCallback } from "react";

import { ClassInquiryType, useChatContext } from "../chatProvider";
import { Message } from "../components/Message";
import { ClassInquiryStatus, Sender } from "../consts";

const ActivityListing = lazy(() =>
  import("@outschool/ui-components-website/src/Listings/MobileListing").then(
    module => ({
      default: module.MobileListing
    })
  )
);

type SendClassRecommendationMessageProp = {
  classInquiryData: ClassInquiryType;
};
export const SendClassRecommendationMessage = ({
  classInquiryData
}: SendClassRecommendationMessageProp) => {
  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");

  const trackAdEvent = useTrackEvent();
  const navigate = useNavigation();
  const isMobile = useIsMobile();

  const { setIsModalOpen } = useChatContext();

  const topRecommendedActivityListing:
    | ClassInquiryType["topRecommendedActivityListing"]
    | undefined = classInquiryData?.topRecommendedActivityListing;

  const searchPath = classInquiryData?.searchPath;

  const requestWasSharedWithTeachers =
    classInquiryData.classInquiries[0]?.status === ClassInquiryStatus.Eligible;

  const messageText = requestWasSharedWithTeachers
    ? t(
        "Your request has been sent to top teachers and will be reviewed. You will receive a direct message as soon as the perfect teacher is found. (You may send up to 3 teacher requests a day)"
      )
    : t(
        "Please review this class that could be perfect for your learner. We didn't share this request with teachers because 3 requests have already been shared today."
      );

  const onClickSeeOtherSuggestedClasses = useCallback(() => {
    trackAdEvent("teacher_pull_see_other_suggested_classes_clicked");

    if (!!setIsModalOpen) {
      setIsModalOpen(false);
    }
    if (searchPath) {
      navigate(searchPath);
    }
  }, [searchPath, navigate, trackAdEvent, setIsModalOpen]);

  return (
    <Message data-private text={messageText} sender={Sender.System}>
      <>
        {topRecommendedActivityListing && (
          <>
            {requestWasSharedWithTeachers && (
              <Typography>
                {t(
                  "In the meantime, here’s a class that could be perfect for your learner."
                )}
              </Typography>
            )}
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                width: "100%",
                overflow: "hidden",
                boxShadow:
                  "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 16px 56px 0px rgba(0, 0, 0, 0.16)",
                flexShrink: 0
              }}
            >
              <ActivityListing
                activity={topRecommendedActivityListing.activity}
                leader={topRecommendedActivityListing.leader}
                nextSection={null}
                disabled={false}
                pageLocation="SEARCH_CLASS_INQUIRY"
                openInNewTab={true}
                trackingProperties={{ fromTeacherPullChat: true }}
                isMiniListingCard={isMobile}
                showCTAButton={true}
              />
            </Box>
          </>
        )}
        {searchPath && (
          <Button
            size="small"
            variant="outlined"
            sx={(theme: Theme) => ({
              maxHeight: "3.4rem",
              width: "50%",
              [theme.breakpoints.down("md")]: {
                width: "100%"
              }
            })}
            onClick={onClickSeeOtherSuggestedClasses}
          >
            {t("See other suggested classes")}
          </Button>
        )}
      </>
    </Message>
  );
};
