import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalContent,
  Typography
} from "@outschool/backpack";
import {
  EmailPreferenceFragmentFragment,
  EmailPreferenceGroupFragmentFragment,
  SubscribeToCreationEmailsMutation,
  SubscribeToCreationEmailsMutationVariables
} from "@outschool/gql-frontend-generated";
import { Trans, useTranslation } from "@outschool/localization";
import { privacyPath, termsPath } from "@outschool/routes";
import {
  Integration,
  useAnalytics,
  useTrackEvent
} from "@outschool/ui-analytics";
import {
  gql,
  useMutation,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import { useLinkComponent } from "@outschool/ui-utils";
import React, { useCallback, useEffect, useState } from "react";

import { emailPreferencesQuery } from "../queries/EmailPreferenceQueries";

export const ONE_TAP_SIGNUP_STORAGE_KEY = "showOneTapConsentModal";

interface OneTapConsentModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const subscribeMutation = gql`
  mutation SubscribeToCreationEmails($attribution: Attribution) {
    subscribeToCreationEmails(attribution: $attribution)
  }
`;

export default function OneTapConsentModal({
  setShowModal
}: OneTapConsentModalProps) {
  const { t } = useTranslation("client\\components\\OneTapConsentModal");
  const Link = useLinkComponent();
  const trackEvent = useTrackEvent();
  const analytics = useAnalytics();
  const [isSubscribing, setIsSubscribing] = useState(false);

  const { error, data } = useQueryWithPreviousData(emailPreferencesQuery);
  // if "occasional updates" are on, the user was already subscribed during signup
  const occasionalUpdates = data?.currentUser?.emailPreferences
    ?.find(
      (preference: EmailPreferenceGroupFragmentFragment) =>
        preference.name === "EMAILS_FOR_ALL_USERS"
    )
    ?.childPreferences?.find(
      (childPreference: EmailPreferenceFragmentFragment) =>
        childPreference.name === "OCCASIONAL_UPDATES_ABOUT_OUTSCHOOL"
    );
  const shouldShowSubscriptionOption =
    !error && (!occasionalUpdates || occasionalUpdates.isEnabled === false);

  const shouldRender = !!data || !!error;
  useEffect(() => {
    if (shouldRender) {
      trackEvent("view_one_tap_terms", {
        showSubscription: shouldShowSubscriptionOption
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRender, shouldShowSubscriptionOption]);

  const [subscribe] = useMutation<
    SubscribeToCreationEmailsMutation,
    SubscribeToCreationEmailsMutationVariables
  >(subscribeMutation);

  const onModalClose = useCallback(async () => {
    if (isSubscribing) {
      const attribution = await analytics.attribution();
      await subscribe({
        variables: { attribution }
      });
      trackEvent(
        "Updated preference",
        { property: "subscribe" },
        {
          integrations: {
            [Integration.Intercom]: true
          }
        }
      );
    }
    trackEvent("accept_one_tap_terms", { subscribed: isSubscribing });
    setShowModal(false);
  }, [isSubscribing, analytics, subscribe, trackEvent, setShowModal]);

  if (shouldRender) {
    return (
      <Modal open={true} sx={{ marginX: "1em" }}>
        <ModalContent sx={{ maxWidth: 450 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingY: "1em"
            }}
          >
            <Typography
              variant="h5"
              sx={{ marginBottom: "1.5em", fontWeight: 700 }}
            >{t`Welcome to Outschool!`}</Typography>
            <Trans t={t}>
              <Typography sx={{ color: "gray.600", textAlign: "center" }}>
                By clicking “Agree and Continue”, I agree to Outschool’s{" "}
                <Link target="_blank" to={termsPath()}>
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link target="_blank" to={privacyPath()}>
                  Privacy Policy
                </Link>
                .
              </Typography>
            </Trans>
            {shouldShowSubscriptionOption && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "1.5em"
                }}
              >
                <Checkbox
                  checked={isSubscribing}
                  onChange={e => setIsSubscribing(e.target.checked)}
                  sx={{ paddingTop: 0, paddingLeft: 0 }}
                />
                <Typography sx={{ color: "gray.600" }}>
                  {t`I agree to receive marketing communications, including recommendations and offers, from Outschool. Opt-out anytime by going to your setting preferences.`}
                </Typography>
              </Box>
            )}
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onModalClose}
              sx={{ marginTop: "1.5em" }}
            >{t`Agree and Continue`}</Button>
          </Box>
        </ModalContent>
      </Modal>
    );
  }

  return null;
}
