import { Box, Theme, Typography, useTheme } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { joinMembershipPath } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { CreditIcon, TrackedButton } from "@outschool/ui-components-shared";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router";

import { SubscriptionEsaDisableTooltip } from "./SubscriptionEsaDisableTooltip";

export function JoinSubscriptionNavItem() {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigation();
  const { isEsaSession } = useSession();
  const { t } = useTranslation(
    "client\\components\\nav\\JoinSubscriptionNavItem"
  );
  return (
    <Box
      sx={(theme: Theme) => ({
        "&:hover": {
          color: theme.palette.grey[900],
          border: 0,
          borderBottom: 4,
          borderStyle: "solid",
          borderColor: theme.palette.grey[400]
        }
      })}
    >
      <SubscriptionEsaDisableTooltip
        tooltipComponent={
          <Trans t={t}>
            Memberships aren't available with state ESA, microgrant,
            scholarships or public funds. For additional information, contact{" "}
            <a href="mailto:support@outschool.com">support@outschool.com</a>.
          </Trans>
        }
      >
        <TrackedButton
          variant="link"
          trackingName="subscription_join_nav_item"
          sx={{
            border: "none",
            paddingX: 8,
            paddingTop: 6,
            display: "block",
            color: theme.palette.common.black,
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              paddingTop: "18px"
            },
            textDecoration: "none !important"
          }}
          onClick={(_e: React.MouseEvent<HTMLButtonElement>) => {
            navigate(
              joinMembershipPath({ redirectAfterPath: location.pathname })
            );
          }}
        >
          <>
            <Box>
              <CreditIcon
                fillColor={
                  isEsaSession
                    ? theme.palette.grey[300]
                    : theme.palette.warning.main
                }
                strokeColor={
                  isEsaSession
                    ? theme.palette.grey[700]
                    : theme.palette.warning[700]
                }
                sx={{ width: "2.4rem", height: "2.4rem" }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: isEsaSession ? "grey.500" : theme.palette.common.black,
                fontWeight: 500,
                fontSize: "1.2rem",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1.6rem",
                  marginLeft: "1rem"
                }
              }}
            >
              {t("Save up to 20%")}
            </Typography>
          </>
        </TrackedButton>
      </SubscriptionEsaDisableTooltip>
    </Box>
  );
}
