import {
  stripeBillingPath,
  stripeCheckoutPath,
  websiteHost
} from "@outschool/routes";
import {
  AuthTrigger,
  useLoginFlowContext,
  useSession
} from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router";

import * as Env from "../../../../shared/Env";

const REDIRECT_PARAM_NAME = "redirect";
const REDIRECT_PARAM_VALUE = "true";

const SELECTION_PARAM_NAME = "selection";

/**
 * The interaction will go as this:
 *
 * Click on the package
 * Check session
 *   No session
 *     Redirect to signup
 *     After signup or login
 *       Land back on the page and kick off the redirect to stripe checkout by keeping the product selected
 *   Has session
 *     Pick the package and take them to stripe checkout
 *
 */

const setRedirectAfterLoginParamInUrl = (url: URL, selection?: string) => {
  url.searchParams.set(REDIRECT_PARAM_NAME, REDIRECT_PARAM_VALUE);
  selection && url.searchParams.set(SELECTION_PARAM_NAME, selection);
  return url;
};

const isRedirectParamInUrl = (url: URL) => {
  return (
    url.searchParams.get(REDIRECT_PARAM_NAME) === REDIRECT_PARAM_VALUE &&
    url.searchParams.get(SELECTION_PARAM_NAME) &&
    url.searchParams.get("signup") !== "true"
  );
};

function getURLFromLocation<L extends { pathname: string; search: string }>(
  location: L,
  urlBase: string
) {
  return new URL(location.pathname + location.search, urlBase);
}

function getSelectionFromSearch(search: string) {
  const params = new URLSearchParams(search);
  return params.get(SELECTION_PARAM_NAME) ?? undefined;
}

type StripeCheckoutSessionArgs = {
  selection?: string;
  onCancelPath?: string;
  onSuccessPath?: string;
};

/**
 *
 * Handles the checkout flow with stripe hosted flow.
 *
 * It prompts to signup or login, then displays a modal with purchase
 * information before redirecting to stripe hosted page.
 *
 */
export function useStripeCheckout({
  returnPath = "/"
}: { returnPath?: string } = {}) {
  const session = useSession();
  const { enterLoginFlow } = useLoginFlowContext();
  const location = useLocation();
  const navigate = useNavigation();
  const [isLoading, setIsLoading] = React.useState(false);

  const createCheckoutSessionAndRedirect = React.useCallback(
    ({ selection, onCancelPath, onSuccessPath }: StripeCheckoutSessionArgs) => {
      if (!session.isLoggedIn || Env.IS_READ_ONLY_MODE) {
        setIsLoading(false);
        return;
      }

      if (!selection) {
        // TODO: Improve error details
        setIsLoading(false);
        throw new Error("Stripe checkout flow");
      }

      setIsLoading(true);
      fetch(stripeCheckoutPath(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.sessionToken}`
        },
        body: JSON.stringify({ selection, onCancelPath, onSuccessPath })
      })
        .then(r => r.text())
        .then(navigate)
        .catch(e =>
          OsPlatform.captureError(e, {
            extra: {
              userUid: session.currentUser?.uid
            }
          })
        )
        .finally(() => setIsLoading(false));
    },
    [session, navigate, setIsLoading]
  );

  const createBillingSessionAndRedirect = React.useCallback(
    ({
      newTab = false,
      onReturnPath
    }: {
      newTab: boolean;
      onReturnPath?: string;
    }) => {
      if (!session.isLoggedIn || Env.IS_READ_ONLY_MODE) {
        return;
      }
      return fetch(stripeBillingPath(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.sessionToken}`
        },
        body: JSON.stringify({ onReturnPath })
      })
        .then(r => r.text())
        .then(url => navigate(url, { newTab }))
        .catch(e =>
          OsPlatform.captureError(e, {
            extra: {
              userUid: session.currentUser?.uid
            }
          })
        );
    },
    [session, navigate]
  );

  const redirectAfterLogin = React.useCallback(() => {
    if (
      session.isLoggedIn &&
      isRedirectParamInUrl(getURLFromLocation(location, websiteHost()))
    ) {
      const selection = getSelectionFromSearch(location.search);
      createCheckoutSessionAndRedirect({ selection });
    }
  }, [session, location, createCheckoutSessionAndRedirect]);

  const enterStripeCheckoutSessionFlow = React.useCallback(
    ({ selection, onCancelPath, onSuccessPath }: StripeCheckoutSessionArgs) => {
      if (session.isLoggedIn) {
        createCheckoutSessionAndRedirect({
          selection,
          onCancelPath,
          onSuccessPath
        });
      } else {
        const getPostSignupPath = (url: URL) => {
          const newUrl = setRedirectAfterLoginParamInUrl(url, selection);
          return newUrl.pathname + newUrl.search;
        };

        enterLoginFlow({
          shouldNavigateOverwrite: false,
          skipLearners: true,
          newUrl: getPostSignupPath(new URL(returnPath, websiteHost())),
          authTrigger: AuthTrigger.BUY_SUBSCRIPTION
        });
      }
    },
    [
      session.isLoggedIn,
      enterLoginFlow,
      createCheckoutSessionAndRedirect,
      returnPath
    ]
  );

  return {
    redirectAfterLogin,
    createBillingSessionAndRedirect,
    stripeIsLoading: isLoading,

    /**
     *
     * Start off a stripe checkout session. Prompts to login/sign up and then
     * redirect to stripe to continue with the purchase.
     *
     */
    enterStripeCheckoutSessionFlow
  };
}
