import { Box, Icon, Theme, Typography, styled } from "@outschool/backpack";
import {
  faChalkboardTeacher,
  faComment,
  faHome,
  faQuestionCircle
} from "@outschool/icons";
import { useLocale, useTranslation } from "@outschool/localization";
import {
  leadActivitiesPath,
  parentProfilePath,
  teacherDashboardPath,
  teacherSchedulePath
} from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import {
  TrackedButton,
  UserHeadshotImage
} from "@outschool/ui-components-shared";
import { useImpressionTracking } from "@outschool/ui-legacy-component-library";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";
import { NavLink } from "react-router-dom";

import * as Routes from "../../../shared/Routes";
import * as User from "../../../shared/User";
import { UserMode } from "../../lib/UserMode";
import IconBadgeWithNumber from "../IconBadgeWithNumber";

const StyledLink = styled(NavLink)(({ theme }) => {
  return {
    position: "relative",
    whiteSpace: "nowrap",
    width: "100%",
    textDecoration: "none !important",
    color: theme.palette.grey[500],
    paddingTop: 5,
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: 0,
    borderBottom: 4,
    borderStyle: "solid",
    borderColor: "white",
    "&:hover": {
      color: theme.palette.grey[900],
      border: 0,
      borderBottom: 4,
      borderStyle: "solid",
      borderColor: theme.palette.grey[400]
    },
    ["&[aria-current='page']"]: {
      color: theme.palette.primary.main,
      border: 0,
      borderBottom: 4,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main
    },
    "&:hover:not([aria-current='page'])": {
      color: theme.palette.grey[900]
    }
  };
});

const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: "1.5em",
  ["[aria-current='page'] &"]: { color: theme.palette.primary.main }
}));

export function DesktopNavTabs() {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  const { currentUser, isLeader } = useSession();
  const isSellerOrg = !!currentUser?.sellerOrg?.currentUserIsOwner;
  const isOnOwnProfile = currentUser
    ? // eslint-disable-next-line no-restricted-globals
      location.pathname.includes("/parents/" + currentUser.uid)
    : false;

  return (
    <Box flex sx={{ alignItems: "flex-end", gap: 16 }}>
      <DesktopNavTab
        to={"/"}
        trackingName={"home"}
        isActive={(_, location) => {
          return (
            !location.pathname.includes("/search") &&
            !location.pathname.includes("/account/saved-classes") &&
            !isOnOwnProfile &&
            !location.pathname.includes("/conversations") &&
            !location.pathname.includes("/account/parent") &&
            !location.pathname.includes("/join-membership")
          );
        }}
        icon={<StyledIcon icon={faHome} />}
      >
        {t("Home")}
      </DesktopNavTab>
      <DesktopNavTab
        to={Routes.conversationsPath(
          isLeader ? UserMode.Educator : UserMode.Parent
        )}
        trackingName={"messages"}
        icon={<StyledIcon icon={faComment} />}
      >
        {t("Messages")}
        <IconBadgeWithNumber
          color="red"
          count={User.unreadConversationsCount(currentUser)}
          sx={{ position: "absolute", right: 0, top: 0 }}
          dataTestId={"unread-message-count"}
        />
      </DesktopNavTab>
      <DesktopNavTab
        isActive={(_, location) => {
          return (
            isOnOwnProfile || location.pathname.includes("/account/parent")
          );
        }}
        to={parentProfilePath(currentUser?.uid || "")}
        trackingName={"profile"}
        icon={
          <Box
            className="amp-block"
            sx={(theme: Theme) => ({
              position: "relative",
              border: 2,
              borderColor: theme.palette.grey[500],
              borderRadius: "50%"
            })}
          >
            <UserHeadshotImage size={24} user={currentUser} />
            {isOnOwnProfile && (
              <Box
                sx={() => ({
                  position: "absolute",
                  width: 26,
                  height: 26,
                  border: `2px solid ${"primary.main"}`,
                  borderRadius: "50%",
                  top: -1,
                  left: -1
                })}
              />
            )}
          </Box>
        }
      >
        {t("Profile")}
      </DesktopNavTab>
      <DesktopHelpNavTab />
      <DesktopNavTab
        to={
          isSellerOrg
            ? teacherSchedulePath()
            : isLeader
            ? teacherDashboardPath()
            : leadActivitiesPath()
        }
        trackingName="nav_teach_link"
        icon={
          <StyledIcon
            icon={faChalkboardTeacher}
            sx={{ width: "1em", height: "28px" }}
          />
        }
      >
        {t("Teach")}
      </DesktopNavTab>
    </Box>
  );
}

function DesktopHelpNavTab() {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  const locale = useLocale();
  const navigate = useNavigation();

  const onHelpTabClick = React.useCallback(() => {
    const intercom = (window as any)?.Intercom;
    if (intercom) {
      try {
        intercom("show");
      } catch (_e) {
        navigate(`https://support.outschool.com/${locale}`, { newTab: true });
      }
    } else {
      navigate(`https://support.outschool.com/${locale}`, { newTab: true });
    }
  }, [locale, navigate]);
  return (
    <TrackedButton
      variant="link"
      onClick={onHelpTabClick}
      sx={{ textDecoration: "none !important" }}
    >
      <Box
        sx={(theme: Theme) => ({
          display: "flex",
          flexDirection: "column",
          paddingTop: "5px",
          alignItems: "center",
          color: theme.palette.grey[500],
          border: 0,
          borderBottom: 4,
          borderStyle: "solid",
          borderColor: "white",
          "&:hover": {
            color: theme.palette.grey[900],
            border: 0,
            borderBottom: 4,
            borderStyle: "solid",
            borderColor: theme.palette.grey[400]
          }
        })}
      >
        <Icon
          icon={faQuestionCircle}
          sx={{
            width: "1em",
            height: "1em",
            margin: 2.5,
            fontSize: "1.5em"
          }}
        />
        <Typography
          variant="overline"
          sx={{
            fontSize: "1.2rem",
            letterSpacing: "0.2px",
            fontWeight: 400,
            paddingX: 4
          }}
        >
          {t("Help")}
        </Typography>
      </Box>
    </TrackedButton>
  );
}

function DesktopNavTab({
  icon,
  children,
  to,
  trackingName,
  onClick,
  ...props
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
  trackingName: string;
  onClick?: () => void;
} & React.ComponentProps<typeof NavLink>) {
  const { trackTouch } = useImpressionTracking({
    trackingLabel: trackingName,
    uniqueId: `${trackingName}_mobile_footer_nav`,
    trackingEventName: `${trackingName}_mobile_footer_nav_event`
  });

  return (
    <StyledLink
      to={to}
      {...props}
      onClick={() => {
        trackTouch();
        if (onClick) {
          onClick();
        }
      }}
    >
      {icon}
      <Typography
        variant="overline"
        sx={{
          fontSize: "1.2rem",
          letterSpacing: "0.2px",
          fontWeight: 400,
          paddingX: 4
        }}
      >
        {children}
      </Typography>
    </StyledLink>
  );
}
