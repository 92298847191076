import { gql } from "@outschool/ui-apollo";

import { EmailPreferenceGroupFragment } from "./EmailPreferenceFragments";

export const emailPreferencesQuery = gql`
  query EmailPreferencesQuery {
    currentUser {
      uid
      emailPreferences {
        ...EmailPreferenceGroupFragment
      }
    }
  }
  ${EmailPreferenceGroupFragment}
`;

export const emailPreferencesByTokenQuery = gql`
  query EmailPreferencesByTokenQuery($token: String!) {
    emailPreferences(token: $token) {
      ...EmailPreferenceGroupFragment
    }
  }
  ${EmailPreferenceGroupFragment}
`;
