import { gql } from "@outschool/ui-apollo";
import { ActivityListingFragment } from "@outschool/ui-components-website";

export const classInquiriesByUserQuery = gql`
  query ClassInquiriesByUser($userUid: ID!) {
    getClassInquiriesByUser(userUid: $userUid) {
      uid
      status
    }
  }
`;

export const classInquiriesForTeacherQuery = gql`
  query ClassInquiriesForTeacher($teacherUid: ID!) {
    getTeacherClassInquiries(teacherUid: $teacherUid) {
      teacherRepliedCountToday
      classInquiriesWithLearnerAges {
        uid
        status
        inquiry_text
        learner_age
        created_at
      }
    }
  }
`;

export const teacherHasInquiriesButHasNotRepliedTodayQuery = gql`
  query TeacherHasInquiriesButHasNotRepliedToday($teacherUid: ID!) {
    getTeacherClassInquiries(teacherUid: $teacherUid) {
      teacherHasInquiriesButHasNotRepliedToday
    }
  }
`;

export const requestClassInquiryMutation = gql`
  mutation RequestClassInquiry(
    $userUid: ID!
    $inquiryText: String!
    $learnerUid: ID
    $searchFilters: SearchFilters
    $skipRating: Boolean = false
  ) {
    requestClassInquiry(
      userUid: $userUid
      inquiryText: $inquiryText
      learnerUid: $learnerUid
      searchFilters: $searchFilters
    ) {
      classInquiries {
        uid
        status
      }
      topRecommendedActivityListing {
        ...ActivityListing_listing
      }
      searchPath
    }
  }
  ${ActivityListingFragment}
`;

export const replyParentRequestMutation = gql`
  mutation ReplyParentRequest(
    $teacherUid: ID!
    $classInquiryUid: ID!
    $relevantActivityUid: ID!
    $replyText: String!
  ) {
    replyClassInquiry(
      teacherUid: $teacherUid
      classInquiryUid: $classInquiryUid
      relevantActivityUid: $relevantActivityUid
      replyText: $replyText
    ) {
      uid
      reply_message_uid
    }
  }
`;
