import { ModalWithTitle, Theme } from "@outschool/backpack";
import { faSparkles } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import React from "react";

import { ChatProvider } from "./chatProvider";
import { ChatWindow } from "./ChatWindow";
import { CHAT_FLOW_CONFIGS } from "./consts";

type ConciergeChatModalProps = {
  isOpen: boolean;
  setIsModalOpen: (_: boolean) => void;
};

export default function ConciergeChatModal({
  isOpen,
  setIsModalOpen
}: ConciergeChatModalProps) {
  const { t } = useTranslation("client\\components\\chat");
  const trackEvent = useTrackEvent();

  const closeModal = () => {
    setIsModalOpen(false);
    trackEvent("ai_guide_modal_closed");
  };

  return (
    <ModalWithTitle
      headerTitle={t("Outschool Guide")}
      headerIcon={faSparkles}
      closeButtonArialLabel={t("Close chat")}
      open={isOpen}
      onClose={closeModal}
      modalContentProps={{
        sx: (theme: Theme) => ({
          // eslint-disable-next-line i18next/no-literal-string
          [theme.breakpoints.down("md")]: {
            height: "100dvh",
            // eslint-disable-next-line i18next/no-literal-string
            margin: "0 auto",
            // eslint-disable-next-line i18next/no-literal-string
            maxHeight: "100dvh"
          }
        })
      }}
    >
      <ChatProvider
        initState={CHAT_FLOW_CONFIGS.concierge.initState}
        setIsModalOpen={setIsModalOpen}
      >
        <ChatWindow />
      </ChatProvider>
    </ModalWithTitle>
  );
}
