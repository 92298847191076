import { Box, Theme } from "@outschool/backpack";
import React, { useEffect, useRef } from "react";

import { useChatContext } from "./chatProvider";
import { LoadingMessage } from "./components/LoadingMessage";
import { HelpMeWithCurrentClassInput } from "./conciergeChat/HelpMeWithCurrentClassInput";
import { HelpMeWithCurrentClassMessage } from "./conciergeChat/HelpMeWithCurrentClassMessage";
import { MESSAGE_ID } from "./consts";
import { SendClassRequestInput } from "./teacherPullChat/SendClassRequestInput";
import { SendClassRequestMessage } from "./teacherPullChat/SendClassRequestMessage";
import { Welcome } from "./Welcome";
import { WelcomeActions } from "./WelcomeActions";

export const ChatWindow = () => {
  const { currentMessageId, conversation } = useChatContext();
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bottomRef?.current) {
      // Scroll to div at bottom of screen when new messages arrive
      // Use timeout so that scroll always occurs after render
      let timer = setTimeout(function () {
        bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);

      return () => {
        clearTimeout(timer);
      };
    } else {
      return;
    }
  }, [currentMessageId, conversation]);

  return (
    <Box
      flex
      sx={(theme: Theme) => ({
        padding: theme.spacing(16),
        width: "100%",
        overflowY: "auto",

        [theme.breakpoints.down("md")]: {
          height: "92.5dvh"
        },
        [theme.breakpoints.up("md")]: {
          maxHeight: "85dvh"
        }
      })}
    >
      <Box
        flex
        sx={{
          flexDirection: "column",
          gap: "1rem",
          marginTop: "auto"
        }}
      >
        {/*render conversations */}
        {conversation?.map((message, index) => (
          <React.Fragment key={index}>{message.render()}</React.Fragment>
        ))}

        {/*render current message */}
        {currentMessageId === MESSAGE_ID.WELCOME && (
          <>
            <Welcome />
            <WelcomeActions />
          </>
        )}

        {currentMessageId === MESSAGE_ID.HELP_ME_WITH_CURRENT_CLASS && (
          <>
            <HelpMeWithCurrentClassMessage />
            <HelpMeWithCurrentClassInput />
          </>
        )}

        {currentMessageId === MESSAGE_ID.LOADING && <LoadingMessage />}

        {currentMessageId === MESSAGE_ID.SEND_CLASS_REQUEST && (
          <>
            <SendClassRequestMessage />
            <SendClassRequestInput />
          </>
        )}

        {currentMessageId === MESSAGE_ID.RETRY_SEND_CLASS_REQUEST && (
          <SendClassRequestInput />
        )}

        {currentMessageId === MESSAGE_ID.WELCOME_ACTIONS && <WelcomeActions />}

        <div ref={bottomRef} />
      </Box>
    </Box>
  );
};
