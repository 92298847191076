import { Search } from "@outschool/business-rules";
import {
  RequestClassInquiryMutation,
  RequestClassInquiryMutationVariables
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useMutation } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import omit from "lodash/omit";
import React, { useContext, useState } from "react";

import { SearchFiltersContext } from "../../../lib/useSearchFilters";
import {
  classInquiriesByUserQuery,
  requestClassInquiryMutation
} from "../../../queries/ClassInquiryQueries";
import { useChatContext } from "../chatProvider";
import { ChatInputField } from "../components/ChatInputField";
import { LearnerSelection } from "../components/LearnerSelection";

export const SendClassRequestInput = () => {
  const [inputValue, setInputValue] = useState("");

  const { t } = useTranslation("client\\components\\chat\\teacherPullChat");

  const trackEvent = useTrackEvent();
  const { currentUser } = useSession();
  const searchContext = useContext(SearchFiltersContext);
  const { sendClassInquirySubmit, classInquiryCreated, selectedLearner } =
    useChatContext();

  const [requestClassInquiry] = useMutation<
    RequestClassInquiryMutation,
    RequestClassInquiryMutationVariables
  >(requestClassInquiryMutation, {
    refetchQueries: [
      {
        query: classInquiriesByUserQuery,
        variables: { userUid: currentUser?.uid }
      }
    ]
  });

  const { filters } = searchContext;

  const cleanedFilters = {
    ...omit(filters, [Search.SCHEDULE_PLANNER_TRACKER])
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    trackEvent("teacher_pull_chat_request_submit_button_clicked");

    if (inputValue) {
      sendClassInquirySubmit(inputValue);

      try {
        const { data: classInquiryData } = await requestClassInquiry({
          variables: {
            userUid: currentUser?.uid || "",
            inquiryText: inputValue,
            learnerUid: selectedLearner?.uid,
            searchFilters: cleanedFilters
          }
        });

        if (!!classInquiryData?.requestClassInquiry) {
          classInquiryCreated(classInquiryData?.requestClassInquiry);
        }
      } catch (error) {
        OsPlatform.captureError(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ChatInputField
        inputHelperText={t(
          "Describe what you’re looking for, including things like topic, time of day and details about your learner."
        )}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      >
        <LearnerSelection label={t("Select a Learner")} />
      </ChatInputField>
    </form>
  );
};
