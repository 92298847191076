import {
  Box,
  Button,
  Chip,
  Icon,
  Theme,
  Tooltip,
  Typography
} from "@outschool/backpack";
import {
  faArrowUpRightFromSquare,
  faBan,
  faExclamationTriangle
} from "@outschool/icons";
import {
  Trans,
  renderCurrencyString,
  useTranslation
} from "@outschool/localization";
import { SupportRoutes, joinMembershipPath } from "@outschool/routes";
import { dayjs, formatDate } from "@outschool/time";
import { useSession } from "@outschool/ui-auth";
import { ExternalLink, TrackedButton } from "@outschool/ui-components-shared";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router";

import * as Env from "../../../shared/Env";
import { useSubscriptionCreditBalance } from "../../hooks/useSubscriptionCreditBalance";
import { useTimeZone } from "../../providers/TimeZoneProvider";
import { useStripeCheckout } from "../../routes/Subscriptions/hooks/useStripeCheckout";
import { SubscriptionEsaDisableTooltip } from "../nav/SubscriptionEsaDisableTooltip";

export enum StripeSubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  PastDue = "past_due"
}

export default function SubscriptionMembershipCard() {
  const location = useLocation();
  const { t } = useTranslation(
    "client\\components\\transactions\\SubscriptionMembershipCard"
  );
  const {
    status,
    availableCredits,
    subscriptionCreditLimit,
    rolloverSubscriptionCreditLimit,
    nextSubscriptionStartDate,
    loading: loadingSubscriptionCredits,
    cancelAtPeriodEnd,
    amountCents,
    upcomingPackageCredits,
    upcomingPackageCost
  } = useSubscriptionCreditBalance();
  const { isEsaSession } = useSession();
  const navigate = useNavigation();
  const { timeZone } = useTimeZone();
  const { createBillingSessionAndRedirect } = useStripeCheckout();
  const [isCreditsModalOpen, setIsCreditsModalOpen] = React.useState(false);
  const [AddCreditsModal, setAddCreditsModal] =
    React.useState<React.ComponentType<any> | null>(null);

  const handleOpenCreditsModal = async () => {
    // Dynamically import the modal when the button is clicked
    const module = await import(
      /* webpackChunkName: "subscriptions" */ "../subscriptions/AddCreditsModal"
    );
    setAddCreditsModal(() => module.default);
    setIsCreditsModalOpen(true);
  };

  const hasSubscriptionEnded =
    status === StripeSubscriptionStatus.Canceled &&
    nextSubscriptionStartDate &&
    dayjs().isAfter(nextSubscriptionStartDate);

  if (loadingSubscriptionCredits || !status) {
    return null;
  }

  const managingMembershipUrl = SupportRoutes.managingMembershipUrl();

  return (
    <>
      <Box
        flex
        sx={{
          backgroundColor: "primary.50",
          padding: "1em 1.25em",
          flexDirection: "column",
          gap: "0.5em",
          alignItems: "stretch",
          borderRadius: "16px",
          width: "100%"
        }}
      >
        <Typography emphasized variant="h6" sx={{ paddingY: "0.25em" }}>
          {t("Membership")}
        </Typography>
        <Divider />
        <SubscriptionCreditInfoItem
          title={hasSubscriptionEnded ? t("Latest Plan") : t("Current Plan")}
        >
          <Box
            flex
            sx={{
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center",
              flexGrow: 2,
              flexWrap: "wrap"
            }}
          >
            <Typography>
              {t("{{numCreditsInPlan}} credits", {
                numCreditsInPlan: subscriptionCreditLimit
              })}
            </Typography>
            <SubscriptionStatusChip
              status={status}
              cancelAtPeriodEnd={!!cancelAtPeriodEnd}
              subscriptionEndDate={nextSubscriptionStartDate}
              upcomingPackageCredits={upcomingPackageCredits}
              upcomingPackageCost={upcomingPackageCost}
            />
          </Box>

          <SubscriptionEsaDisableTooltip
            component="SubscriptionMembershipCard"
            tooltip={t(
              "Memberships aren’t eligible for ClassWallet funding. To manage your Membership"
            )}
            placement="top"
          >
            {hasSubscriptionEnded ? (
              <Button
                variant="link"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  navigate(joinMembershipPath());
                }}
                size="small"
                sx={{
                  fontWeight: "fontWeightMedium",
                  paddingY: "4px",
                  textWrap: "wrap"
                }}
              >
                {t("Reactivate Your Membership")}
              </Button>
            ) : (
              <Button
                variant="link"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  createBillingSessionAndRedirect({
                    newTab: true,
                    onReturnPath: location.pathname
                  });
                }}
                size="small"
                sx={{
                  fontWeight: "fontWeightMedium",
                  paddingY: "4px",
                  textWrap: "wrap"
                }}
                disabled={isEsaSession || Env.IS_READ_ONLY_MODE}
              >
                {status === StripeSubscriptionStatus.PastDue
                  ? t("Update Payment Method")
                  : t("Manage Plan")}
                <Icon
                  icon={faArrowUpRightFromSquare}
                  sx={{ marginLeft: "0.25em" }}
                />
              </Button>
            )}
          </SubscriptionEsaDisableTooltip>
        </SubscriptionCreditInfoItem>
        {status !== StripeSubscriptionStatus.Canceled && (
          <>
            <Divider />
            <SubscriptionCreditInfoItem title={t("Credit Usage")}>
              <Typography sx={{ flexGrow: 2 }}>
                {t("{{numCreditsRemaining}} credits remaining", {
                  numCreditsRemaining: availableCredits
                })}
              </Typography>
              <SubscriptionEsaDisableTooltip
                component="SubscriptionMembershipCard"
                tooltip={t(
                  "Memberships aren’t eligible for ClassWallet funding. To manage your Membership"
                )}
              >
                <TrackedButton
                  size="small"
                  variant="link"
                  trackingName="membership_card_add_credit_button"
                  onClick={handleOpenCreditsModal}
                  sx={{ paddingY: "4px" }}
                  disabled={isEsaSession || Env.IS_READ_ONLY_MODE}
                >
                  {t("Get More Credits")}
                </TrackedButton>
              </SubscriptionEsaDisableTooltip>
            </SubscriptionCreditInfoItem>
            <Divider />
            <SubscriptionCreditInfoItem title={t("Billing")}>
              <Typography variant="body1" sx={{ textWrap: "wrap" }}>
                {cancelAtPeriodEnd
                  ? t("{{pricePerMonth}}/month", {
                      pricePerMonth: renderCurrencyString({
                        priceInCents: amountCents
                      })
                    })
                  : !!upcomingPackageCost
                  ? t(
                      "{{pricePerMonth}}/month, next bill of {{upcomingPackageCost}} on {{nextBillingDate}}",
                      {
                        pricePerMonth: renderCurrencyString({
                          priceInCents: amountCents
                        }),
                        upcomingPackageCost: renderCurrencyString({
                          priceInCents: upcomingPackageCost
                        }),
                        nextBillingDate: formatDate(
                          nextSubscriptionStartDate,
                          timeZone,
                          true
                        )
                      }
                    )
                  : t(
                      "{{pricePerMonth}}/month, next bill on {{nextBillingDate}}",
                      {
                        pricePerMonth: renderCurrencyString({
                          priceInCents: amountCents
                        }),
                        nextBillingDate: formatDate(
                          nextSubscriptionStartDate,
                          timeZone,
                          true
                        )
                      }
                    )}
              </Typography>
            </SubscriptionCreditInfoItem>
            <Divider />

            {!cancelAtPeriodEnd && (
              <Trans t={t}>
                <Typography variant="caption" sx={{ paddingY: "0.25em" }}>
                  Any unused credits will roll over, up to{" "}
                  {{ numMaxRolloverCredits: rolloverSubscriptionCreditLimit }}{" "}
                  credits per month with your current plan.{" "}
                  <ExternalLink url={managingMembershipUrl}>
                    Learn more
                  </ExternalLink>
                </Typography>
              </Trans>
            )}
            {cancelAtPeriodEnd && (
              <Typography variant="caption" sx={{ paddingY: "0.25em" }}>
                {t(
                  "Once your membership ends, you will no longer have access to unused credits or savings."
                )}
              </Typography>
            )}
          </>
        )}
        {status === StripeSubscriptionStatus.Canceled && (
          <>
            <Divider />
            <Typography variant="caption" sx={{ paddingY: "0.25em" }}>
              {t(
                "Reactivate your membership to get access to savings and no fees."
              )}
            </Typography>
          </>
        )}
      </Box>
      {AddCreditsModal && (
        <AddCreditsModal
          isOpen={isCreditsModalOpen}
          onClose={() => {
            setIsCreditsModalOpen(false);
            setAddCreditsModal(null);
          }}
        />
      )}
    </>
  );
}

function Divider() {
  return <Box sx={{ borderBottom: "1px solid", borderColor: "grey.200" }} />;
}

function SubscriptionCreditInfoItem({
  title,
  children
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Box
      flex
      sx={(theme: Theme) => ({
        flexDirection: "row",
        gap: "0.5em",
        paddingY: "0.25em",
        alignItems: "baseline",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column"
        }
      })}
    >
      <Typography
        variant="subtitle1"
        sx={(theme: Theme) => ({
          flex: "0 0 7.5em",
          [theme.breakpoints.down("sm")]: {
            flexBasis: "auto"
          }
        })}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
}

function SubscriptionStatusChip({
  status,
  cancelAtPeriodEnd,
  subscriptionEndDate,
  upcomingPackageCredits
}: {
  status: string;
  cancelAtPeriodEnd: boolean;
  subscriptionEndDate: Date | null;
  upcomingPackageCredits: number | null;
  upcomingPackageCost: number | null;
}) {
  const { t } = useTranslation(
    "client\\components\\transactions\\SubscriptionMembershipCard"
  );
  const { timeZone } = useTimeZone();

  // a subscription can be active and set to renew, active and set to NOT renew, or canceled
  if (status === StripeSubscriptionStatus.Active && cancelAtPeriodEnd) {
    return (
      <Tooltip
        placement="top"
        title={
          <Typography variant="body1">
            {t(
              "Once your membership ends, you will no longer have access to unused credits or savings."
            )}
          </Typography>
        }
      >
        <Chip
          color="warning"
          size="small"
          label={t("Active (Ending {{endDate}})", {
            endDate: formatDate(subscriptionEndDate, timeZone, true)
          })}
          icon={<Icon icon={faExclamationTriangle} />}
        />
      </Tooltip>
    );
  } else if (status === StripeSubscriptionStatus.PastDue) {
    return (
      <Tooltip
        placement="top"
        title={
          <Typography variant="body1">
            {t(
              "Payment failed. Update your payment method by {{cancellationDate}} to keep your credits. Once processed, your monthly credits will be added.",
              {
                cancellationDate: formatDate(
                  dayjs(subscriptionEndDate).add(7, "days").toDate(),
                  timeZone,
                  true
                )
              }
            )}
          </Typography>
        }
      >
        <Chip
          color="error"
          size="small"
          label={t("Action Required")}
          sx={{
            cursor: "pointer"
          }}
          icon={<Icon icon={faExclamationTriangle} />}
        />
      </Tooltip>
    );
  } else if (
    status === StripeSubscriptionStatus.Active &&
    !!upcomingPackageCredits
  ) {
    return (
      <Chip
        color="success"
        size="small"
        label={t("Next Cycle: {{upcomingPackageCredits}} credit plan", {
          upcomingPackageCredits
        })}
      />
    );
  } else if (status === StripeSubscriptionStatus.Active) {
    return <Chip color="success" size="small" label={t("Active")} />;
  } else if (status === StripeSubscriptionStatus.Canceled) {
    return (
      <Chip
        color="error"
        size="small"
        label={t("Canceled ({{endDate}})", {
          endDate: formatDate(subscriptionEndDate, timeZone, true)
        })}
        icon={<Icon icon={faBan} />}
      />
    );
  }

  return null;
}
