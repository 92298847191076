import {
  Box,
  Button,
  Icon,
  Theme,
  Tooltip,
  Typography
} from "@outschool/backpack";
import { faMagnifyingGlass, faPenToSquare } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import React from "react";

import { useConciergeActiveEnrollmentQuery } from "../../hooks/useConciergeActiveEnrollmentQuery";
import { useChatContext } from "./chatProvider";

export function WelcomeActions() {
  const { findANewClassOnClick, helpMeWithACurrentClassOnClick } =
    useChatContext();
  const { t } = useTranslation("client\\components\\chat");

  const { loading: enrollmentsLoading, data: enrollments } =
    useConciergeActiveEnrollmentQuery({});

  const hasEnrollments =
    !enrollmentsLoading &&
    enrollments?.currentUser?.activeEnrollments &&
    enrollments.currentUser.activeEnrollments.length > 0;

  return (
    <Box
      sx={(theme: Theme) => ({
        display: "flex",
        marginLeft: "5rem",
        marginTop: "1rem",
        gap: "1.6rem",
        [theme.breakpoints.down("md")]: {
          marginLeft: "inherit",
          marginTop: "inherit",
          flexDirection: "column"
        }
      })}
    >
      <Button
        sx={(theme: Theme) => ({
          color: theme.palette.neutral[900],
          borderColor: theme.palette.neutral[300],
          padding: "1.8rme 24rem",
          gap: theme.spacing(4),
          "&.Mui-disabled": {
            color: theme.palette.neutral[500],
            backgroundColor: "common.white",
            border: `solid 2px ${theme.palette.neutral.light}`
          },
          [theme.breakpoints.down("md")]: {
            width: "100%"
          }
        })}
        onClick={findANewClassOnClick}
      >
        <Icon icon={faMagnifyingGlass} />
        <Typography
          variant="body2"
          emphasized
        >{t`Find a New Class`}</Typography>
      </Button>
      <Tooltip
        placement="top"
        title={hasEnrollments ? null : t`No current enrollments found`}
      >
        <span>
          <Button
            disabled={!hasEnrollments}
            sx={(theme: Theme) => ({
              color: theme.palette.grey[900],
              borderColor: theme.palette.neutral[300],
              padding: "1.8rme 24rem",
              gap: theme.spacing(4),
              "&.Mui-disabled": {
                color: theme.palette.neutral[500],
                backgroundColor: "common.white",
                border: `solid 2px ${theme.palette.neutral.light}`
              },
              [theme.breakpoints.down("md")]: {
                width: "100%"
              }
            })}
            onClick={helpMeWithACurrentClassOnClick}
          >
            <Icon icon={faPenToSquare} />
            <Typography
              variant="body2"
              emphasized
            >{t`Help me with a current class`}</Typography>
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
}
