import { keyframes } from "@emotion/react";
import { Box, styled } from "@outschool/backpack";
import React from "react";

import { Sender } from "../consts";
import { Message } from "./Message";

const blink = keyframes`
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

const Dot = styled(Box)(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  margin: theme.spacing(0, 0.5),
  backgroundColor: theme.palette.grey[400],
  borderRadius: "50%",
  animation: `${blink} 1.4s infinite both`
}));

export const LoadingMessage = () => {
  return (
    <Message sender={Sender.System} text={""}>
      <Box display="flex" alignItems="center">
        <Dot sx={{ animationDelay: "0s" }} />
        <Dot sx={{ animationDelay: "0.2s" }} />
        <Dot sx={{ animationDelay: "0.4s" }} />
      </Box>
    </Message>
  );
};
