import { Learner } from "@outschool/gql-backend-generated";
import { useSession } from "@outschool/ui-auth";
import React from "react";

import { useChatContext } from "../chatProvider";
import { ChatInputSelection } from "./ChatInputSelection";

export type SelectedLearner = Pick<Learner, "uid" | "name" | "age">;

type LearnerSelectionProps = {
  label: string;
};
export const LearnerSelection = ({ label }: LearnerSelectionProps) => {
  const { updateSelectedLearner } = useChatContext();
  const { currentUser } = useSession();
  const learners = currentUser?.children;

  return (
    <>
      {!!learners?.length ? (
        <ChatInputSelection
          label={label}
          options={learners}
          optionLabel={`name`}
          onSelect={(learner: SelectedLearner) =>
            updateSelectedLearner(learner)
          }
        />
      ) : null}
    </>
  );
};
