import React, { useEffect, useRef, useState } from "react";

import { SelectionMenu } from "./SelectionMenu";
import { SelectionMenuItem } from "./SelectionMenuItem";

type SelectionProps<T> = {
  label: string;
  options: T[];
  optionLabel: keyof T;
  onSelect: (_: T) => void;
};

export function ChatInputSelection<T extends { uid: string }>({
  label,
  options,
  optionLabel,
  onSelect
}: SelectionProps<T>) {
  const defaultOption = options.length === 1 ? options[0] : undefined;

  const [selectedOption, setSelectedOption] = useState<T | undefined>(
    defaultOption
  );

  const onSelectRef = useRef(onSelect);

  useEffect(() => {
    onSelectRef.current = onSelect;
  }, [onSelect]);

  useEffect(() => {
    if (defaultOption) {
      onSelectRef.current(defaultOption);
    }
  }, [defaultOption]);

  const handleOnClick = (option: T) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <SelectionMenu
      buttonText={(selectedOption?.[optionLabel] as string) || label}
    >
      {options.map(option => (
        <SelectionMenuItem
          data-private
          key={option.uid}
          onClick={() => handleOnClick(option)}
        >
          {option[optionLabel] as string}
        </SelectionMenuItem>
      ))}
    </SelectionMenu>
  );
}
